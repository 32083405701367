const CommentOffset = [
    {
        value: '',
        label: '댓글 종류',
        form: 'comment'
    },
    {
        value: 'True',
        label: '댓글',
        form: 'comment'
    },
    {
        value: 'False',
        label: '답글',
        form: 'comment'
    }
];

export default CommentOffset;
