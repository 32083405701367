import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as pushAPI from 'fluv/lib/api/pushAPI';
import PushChannelOffset from 'fluv/utils/Offset/PushChannelOffset';
import PushStatusOffest from 'fluv/utils/Offset/PushStatusOffset';
import PushTargetFilterOffest from 'fluv/utils/Offset/PushTargetFilterOffset';
import PushTypeOffset from 'fluv/utils/Offset/PushTypeOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_PUSH';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_PUSH';
const [GET_PUSH, GET_PUSH_SUCCESS, GET_PUSH_FAILURE] = createRequestActionTypes('fluv/GET_PUSH');
const [GET_PUSH_MORE, GET_PUSH_MORE_SUCCESS, GET_PUSH_MORE_FAILURE] = createRequestActionTypes('fluv/GET_PUSH_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getPush = createAction(
    GET_PUSH,
    ({ offset, limit, isReserve, userId, nickname, pushChannel, pushTarget, pushStatus }) => ({
        offset,
        limit,
        isReserve,
        pushChannel,
        pushTarget,
        pushStatus,
        userId,
        nickname
    })
);

export const getPushMore = createAction(
    GET_PUSH_MORE,
    ({ offset, limit, isReserve, userId, nickname, pushChannel, pushTarget, pushStatus }) => ({
        offset,
        limit,
        isReserve,
        pushChannel,
        pushTarget,
        pushStatus,
        userId,
        nickname
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    pushList: null,
    filter: {
        pushType: {
            value: 'True',
            label: '예약 푸시',
            form: 'pushType',
            offset: PushTypeOffset
        },
        pushChannel: {
            value: '',
            label: '채널',
            form: 'pushChannel',
            offset: PushChannelOffset
        },
        pushTarget: {
            value: '',
            label: '푸시 종류',
            form: 'pushTarget',
            offset: PushTargetFilterOffest
        },
        pushStatus: {
            value: '',
            label: '푸시 상태',
            form: 'pushStatus',
            offset: PushStatusOffest
        }
    },
    offset: 0,
    limit: 20,
    getMore: true,
    search: {
        input: '',
        target: 'nickname'
    }
};

// ==============================|| SAGA ||============================== //

const getPushSaga = createRequestSaga(GET_PUSH, pushAPI.getPushes);
const getPushMoreSaga = createRequestSaga(GET_PUSH_MORE, pushAPI.getPushes);

export function* pushSaga() {
    yield takeLatest(GET_PUSH, getPushSaga);
    yield takeLatest(GET_PUSH_MORE, getPushMoreSaga);
}

// ==============================|| REDUCER ||============================== //

const push = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_PUSH_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            pushList: data.data,
            reservePushList: initialState.reservePushList,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PUSH_FAILURE]: state => ({
            ...state,
            pushList: 'error',
            reservePushList: initialState.reservePushList
        }),
        [GET_PUSH_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            pushList: state.pushList.concat(data.data),
            reservePushList: initialState.reservePushList,
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PUSH_MORE_FAILURE]: state => ({
            ...state,
            reservePushList: initialState.reservePushList
        })
    },
    initialState
);

export default push;
