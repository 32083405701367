/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    FLUV Redux
*/
// thire party
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

// FLUV STORE
import banner, { bannerSaga } from './banner';
import bottomSheet, { bottomSheetSaga } from './bottomSheet';
import config, { configSaga } from './config';
import event, { eventSaga } from './event';
import notice, { noticeSaga } from './notice';
import promotion, { promotionSaga } from './promotion';
import user, { userSaga } from './user';

// ==============================|| REDUX - MAIN STORE ||============================== //

const fillitReducer = combineReducers({
    notice,
    event,
    banner,
    user,
    config,
    bottomSheet,
    promotion
});

// ==============================|| REDUX-SAGA - MAIN SAGA ||============================== //

export function* fillitSaga() {
    yield all([noticeSaga(), eventSaga(), bannerSaga(), userSaga(), configSaga(), bottomSheetSaga(), promotionSaga()]);
}

export default fillitReducer;
