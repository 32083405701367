import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getPromotion = ({ locale }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/`, {
        params: { offset: 0, limit: 10, type: 'promotion', locale }
    });

export const getPromotionDetail = ({ promotionId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${promotionId}/`);

export const putPromotion = ({ promotionId, title, url, locale }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${promotionId}/`, {
        promotionId,
        title,
        content: 0,
        priority: 1,
        is_viewable: true,
        locale,
        type: 'promotion',
        url
    });
