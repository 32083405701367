const config = {
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false
};

export const apiConfig = {
    accessToken: 'access_token',
    refreshToken: 'refresh_token',
    worksAccessToken: 'exodus-admin-works-tok',
    worksRefreshToken: 'exodus-admin-works-ref-tok',
    authAdminApiUrl: process.env.REACT_APP_AUTH_ADMIN_API_URL,
    celebAdminApiUrl: process.env.REACT_APP_IDOL_ADMIN_API_URL,
    idolAdminApiUrl: process.env.REACT_APP_CELEB_ADMIN_API_URL,
    fluvAdminApiUrl: process.env.REACT_APP_FLUV_ADMIN_API_URL,
    fillitAdminApiUrl: process.env.REACT_APP_FILLIT_ADMIN_API_URL
};

export default config;
