const PushChannelOffset = [
    {
        value: '',
        label: '채널',
        form: 'pushChannel'
    },
    {
        value: 'fluv-ko',
        label: '한국어 채널',
        form: 'pushChannel'
    },
    {
        value: 'fluv-ja',
        label: '일본어 채널',
        form: 'pushChannel'
    },
    {
        value: 'fluv-en',
        label: '영어 채널',
        form: 'pushChannel'
    },
    {
        value: 'fluv-zh-cn',
        label: '중국어 간체 채널',
        form: 'pushChannel'
    },
    {
        value: 'fluv-zh-tw',
        label: '중국어 번체 채널',
        form: 'pushChannel'
    }
];

export default PushChannelOffset;
