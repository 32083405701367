// material-ui
import { BrowserView, MobileView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';

import PropTypes from 'prop-types';

import MenuCard from 'exodus/components/main/component/menu/MenuCard';
import MenuList from 'exodus/components/main/component/menu/MenuList';
import LogoSection from 'exodus/components/main/component/sidebar/LogoSection';

import { Box } from '@mui/material';

// third-party

// project imports

// ==============================|| SIDEBAR DRAWER ||============================== //

const SubDrawer = ({ matchUpMd, menuItem }) => (
    <>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
        </Box>
        <BrowserView>
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                <MenuList menuItem={menuItem} />
                <MenuCard />
            </PerfectScrollbar>
        </BrowserView>
        <MobileView>
            <Box sx={{ px: 2 }}>
                <MenuList menuItem={menuItem} />
                <MenuCard />
            </Box>
        </MobileView>
    </>
);
SubDrawer.propTypes = {
    matchUpMd: PropTypes.bool,
    menuItem: PropTypes.object
};

export default SubDrawer;
