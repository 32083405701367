/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    User API List
*/
import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getUser = ({
    cursor,
    limit,
    locale,
    emailChecked,
    device,
    status,
    role,
    email,
    userId,
    nickname,
    deviceId,
    exodusUid,
    appsflyerId,
    ipAddr,
    isBlocked,
    isSuperuser
}) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/`, {
        params: {
            cursor,
            limit,
            locale,
            email_checked: emailChecked,
            device_type: device,
            status,
            user_id: userId,
            nickname,
            role,
            email,
            device_id: deviceId,
            exodus_uid: exodusUid,
            appsflyer_id: appsflyerId,
            ipaddr: ipAddr,
            is_blocked: isBlocked,
            is_superuser: isSuperuser
        }
    });

export const getUserProfile = ({ userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/${userId}/`, {});

export const getUserLogComment = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/comment/`, {
        params: { cursor, limit, user_id: userId }
    });

export const getUserLogDiamond = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/diamond/`, {
        params: { cursor, limit, user_id: userId }
    });

export const getUserLogClover = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/clover/`, {
        params: { cursor, limit, user_id: userId }
    });

export const getUserLogGeneral = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/general/`, {
        params: { cursor, limit, user_id: userId }
    });

export const getUserNasGeneral = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/nas/`, {
        params: { cursor, limit, user_id: userId }
    });

export const getUserReport = ({ cursor, limit, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/log/report/`, {
        params: { cursor, limit, user_id: userId }
    });

export const putGrantSuperuser = ({ userId }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/users/${userId}/`, {});

export const postRewardUsers = ({ targetUserList, type, amount, message }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/users/reward/`, {
        target_user_list: targetUserList,
        type,
        amount,
        message
    });

// User Ban API
export const getUserBanList = ({ cursor, limit, userId, nickname, email }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/`, {
        params: {
            cursor,
            limit,
            user_id: userId,
            nickname,
            email
        }
    });

export const postUserBan = ({ userId, typeId, beginAt, endAt }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/`, {
        user_id: userId,
        type_id: typeId,
        begin_at: beginAt,
        end_at: endAt
    });

export const deleteUserBan = ({ userBanId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/${userBanId}/`, {});

// User Ban Type API
export const getUserBanType = ({ cursor, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/type/`, {
        params: {
            cursor,
            limit
        }
    });

export const postUserBanType = ({ ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/type/`, {
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putUserBanType = ({ userBanTypeId, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/type/${userBanTypeId}/`, {
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteUserBanType = ({ userBanTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/users/ban/type/${userBanTypeId}/`, {});

// User Report API
export const getReport = ({ cursor, limit, isChecked }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/`, {
        params: { cursor, limit, is_checked: isChecked }
    });

export const putReport = ({ reportId, isChecked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/${reportId}/`, {
        is_checked: isChecked
    });

// User Report Type API
export const getReportType = ({ cursor, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/type/`, {
        params: { cursor, limit, is_viewable: isViewable }
    });

export const postReportType = ({ priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/type/`, {
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putReportType = ({ reportTypeId, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/type/${reportTypeId}/`, {
        reportTypeId,
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteReportType = ({ reportTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/users/report/type/${reportTypeId}/`, {});

// User Block API
export const putBlock = ({ targetId, isBlocked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/users/block/${targetId}/`, {
        is_blocked: isBlocked
    });
