// project import
import { apiConfig } from 'exodus/config';

const authMiddleware = () => next => action => {
    if (action.type === 'auth/LOGIN_SUCCESS') {
        localStorage.setItem(apiConfig.accessToken, action.payload.data.access_token);
        localStorage.setItem(apiConfig.refreshToken, action.payload.data.refresh_token);
    } else if (action.type === 'auth/LOGOUT') {
        localStorage.removeItem(apiConfig.accessToken);
        localStorage.removeItem(apiConfig.refreshToken);
        localStorage.removeItem('flirting-admin-role');
    }

    const result = next(action);

    return result;
};

export default authMiddleware;
