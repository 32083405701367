/*
    Copyright (C) 2024 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    MenuItme Component - Marketing
*/

/* eslint-disable no-unused-vars */
import { IconUserPlus, IconUsers } from '@tabler/icons';

const icons = {
    IconUserPlus,
    IconUsers
};

const marketing = {
    id: 'marketing',
    title: 'Marketing',
    type: 'group',
    children: [
        {
            id: 'users',
            title: '유저',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'user',
                    title: '유저 관리',
                    type: 'item',
                    url: '/fillit/marketing/users/all',
                    icon: icons.IconUserPlus,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default marketing;
