/*
    Copyright (C) 2023 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    Fillit Contents Menu Routes
*/
import { lazy } from 'react';

import menuItem from 'fillit/menuItems';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

// Artists
const Artists = Loadable(lazy(() => import('fillit/pages/Contents/Artists/all/common')));
const ArtistsAdd = Loadable(lazy(() => import('fillit/pages/Contents/Artists/add/common')));
const ArtistsEdit = Loadable(lazy(() => import('fillit/pages/Contents/Artists/edit/common')));
const ArtistTags = Loadable(lazy(() => import('fillit/pages/Contents/Artists/tag/common')));

const Songs = Loadable(lazy(() => import('fillit/pages/Contents/Songs/all/common')));
const SongsAdd = Loadable(lazy(() => import('fillit/pages/Contents/Songs/add/common')));
const SongsEdit = Loadable(lazy(() => import('fillit/pages/Contents/Songs/edit/common')));
const SongTags = Loadable(lazy(() => import('fillit/pages/Contents/Songs/tag/common')));
const SongDetail = Loadable(lazy(() => import('fillit/pages/Contents/Songs/detail/common')));
const ReleasedSongs = Loadable(lazy(() => import('fillit/pages/Contents/Artists/releasedSong/common')));

const ArtistLogs = Loadable(lazy(() => import('fillit/pages/Contents/Artists/play/common')));
const Plays = Loadable(lazy(() => import('fillit/pages/Contents/Songs/play/common')));

const Lyrics = Loadable(lazy(() => import('fillit/pages/Contents/Lyrics/all/common')));
const Quizzes = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/all/common')));
const QuizzesAdd = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/add/common')));
const QuizDetail = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/detail/common')));
const QuizzesEdit = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/edit/common')));
const QuizSongList = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/songList/common')));
const QuizLogs = Loadable(lazy(() => import('fillit/pages/Contents/Quizzes/play/common')));

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}
const ContentsRoutes = state => {
    if (state && state !== role) {
        window.location.reload();
    }
    return {
        path: '/contents/*',
        element: (
            <AuthGuard>
                <MainLayout menuItem={menuItem} />
            </AuthGuard>
        ),
        children: [
            {
                path: 'artists/all',
                exact: true,
                element: <Artists />
            },
            {
                path: 'artists/add',
                exact: true,
                element: <ArtistsAdd />
            },
            {
                path: 'artists/edit/:artistId',
                exact: true,
                element: <ArtistsEdit />
            },
            {
                path: 'artists/:artistId/songs',
                exact: true,
                element: <ReleasedSongs />
            },
            {
                path: 'artists/:artistId/tags',
                exact: true,
                element: <ArtistTags />
            },
            {
                path: 'artists/:artistId/logs',
                exact: true,
                element: <ArtistLogs />
            },
            {
                path: 'songs/all',
                exact: true,
                element: <Songs />
            },
            {
                path: 'songs/add',
                exact: true,
                element: <SongsAdd />
            },
            {
                path: 'songs/add/:artistId',
                exact: true,
                element: <SongsAdd />
            },
            {
                path: 'songs/detail/:songId',
                exact: true,
                element: <SongDetail />
            },
            {
                path: 'songs/edit/:songId',
                exact: true,
                element: <SongsEdit />
            },
            {
                path: 'songs/:songId/tags',
                exact: true,
                element: <SongTags />
            },
            {
                path: 'songs/:songId/logs',
                exact: true,
                element: <Plays />
            },
            {
                path: 'lyrics/:songId',
                exact: true,
                element: <Lyrics />
            },
            {
                path: 'plays/all',
                exact: true,
                element: <Plays />
            },
            {
                path: 'quizzes/all',
                exact: true,
                element: <Quizzes />
            },
            {
                path: 'quizzes/add',
                exact: true,
                element: <QuizzesAdd />
            },
            {
                path: 'quizzes/:quizId',
                exact: true,
                element: <QuizDetail />
            },
            {
                path: 'quizzes/edit/:quizId',
                exact: true,
                element: <QuizzesEdit />
            },
            {
                path: 'quizzes/:quizId/songs',
                exact: true,
                element: <QuizSongList />
            },
            {
                path: 'quizzes/plays/all',
                exact: true,
                element: <QuizLogs />
            },
            { path: '*', element: <NotFound /> }
        ]
    };
};

export default ContentsRoutes;
