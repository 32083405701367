import { IconCertificate, IconComet, IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconCertificate,
    IconDeviceAnalytics,
    IconComet
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Default',
            type: 'item',
            url: '/flirting/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
        // {
        //     id: 'contest',
        //     title: '명예의 전당',
        //     type: 'collapse',
        //     icon: icons.IconComet,
        //     children: [
        //         {
        //             id: 'contest',
        //             title: '이달의 순위',
        //             type: 'item',
        //             url: '/flirting/dashboard/contest',
        //             icon: icons.IconCertificate,
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'history',
        //             title: '역대 우승자',
        //             type: 'item',
        //             url: '/flirting/dashboard/contest/history',
        //             icon: icons.IconDeviceAnalytics,
        //             breadcrumbs: true
        //         }
        //     ]
        // }
    ]
};

export default dashboard;
