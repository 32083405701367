/*
    Copyright (C) 2024 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    Routes - Marketing
*/

/* eslint-disable no-unused-vars */
import { lazy } from 'react';

import menuItem from 'fillit/menuItems';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

const Users = Loadable(lazy(() => import('fillit/pages/Marketing/Users/all/common')));
const UserDetail = Loadable(lazy(() => import('fillit/pages/Marketing/Users/detail/common')));

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}

const MarketingRoutes = {
    path: '/marketing/*',
    element: (
        <AuthGuard>
            <MainLayout menuItem={menuItem} />
        </AuthGuard>
    ),
    children: [
        {
            path: 'users/all',
            exact: true,
            element: <Users />
        },
        {
            path: 'users/detail/:userId',
            exact: true,
            element: <UserDetail />
        },
        { path: '*', element: <NotFound /> }
    ]
};

export default MarketingRoutes;
