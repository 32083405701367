import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// project import
import { logout } from 'store/modules/exodus/auth';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        navigate('/auth/login', { replace: true });
    }, [dispatch, navigate]);
};

export default Logout;
