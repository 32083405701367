import { Link, useLocation } from 'react-router-dom';

import Celeb from 'exodus/components/ui/logo/CelebLogo';
import Idol from 'exodus/components/ui/logo/ChoedolLogo';
import Exodus from 'exodus/components/ui/logo/ExodusLogo';
import Fluv from 'exodus/components/ui/logo/FluvLogo';
import Fillit from 'exodus/components/ui/logo/LkwkLogo';

import { ButtonBase } from '@mui/material';

const LogoSection = () => {
    const { pathname } = useLocation();
    switch (pathname.split('/')[1]) {
        case 'idol':
            return (
                <ButtonBase disableRipple component={Link} to="/idol/dashboard/default">
                    <Idol />
                </ButtonBase>
            );
        case 'celeb':
            return (
                <ButtonBase disableRipple component={Link} to="/celeb/dashboard/default">
                    <Celeb />
                </ButtonBase>
            );
        case 'flirting':
            return (
                <ButtonBase disableRipple component={Link} to="/flirting/dashboard/default">
                    <Fluv />
                </ButtonBase>
            );
        case 'fillit':
            return (
                <ButtonBase disableRipple component={Link} to="/fillit/dashboard/default">
                    <Fillit />
                </ButtonBase>
            );
        default:
            return (
                <ButtonBase disableRipple component={Link} to="/exodus/profile/self">
                    <Exodus />
                </ButtonBase>
            );
    }
};
export default LogoSection;
