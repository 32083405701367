/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    FLUV Redux
*/
// thire party
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

// FLUV STORE
import article, { articleSaga } from './article';
import banner, { bannerSaga } from './banner';
import bottomSheet, { bottomSheetSaga } from './bottomSheet';
import channel, { channelSaga } from './channel';
import comment, { commentSaga } from './comment';
import config, { configSaga } from './config';
import contest, { contestSaga } from './contest';
import event, { eventSaga } from './event';
import notice, { noticeSaga } from './notice';
import payment, { paymentSaga } from './payment';
import promotion, { promotionSaga } from './promotion';
import push, { pushSaga } from './push/push';
import reservePush, { reservePushSaga } from './push/reservePush';
import report, { reportSaga } from './report/report';
import reportType, { reportTypeSaga } from './report/reportType';
import user, { userSaga } from './user';

// ==============================|| REDUX - MAIN STORE ||============================== //

const fluvReducer = combineReducers({
    notice,
    event,
    banner,
    push,
    reservePush,
    reportType,
    report,
    user,
    channel,
    article,
    payment,
    comment,
    contest,
    config,
    bottomSheet,
    promotion
});

// ==============================|| REDUX-SAGA - MAIN SAGA ||============================== //

export function* fluvSaga() {
    yield all([
        noticeSaga(),
        eventSaga(),
        bannerSaga(),
        pushSaga(),
        reservePushSaga(),
        reportTypeSaga(),
        reportSaga(),
        userSaga(),
        channelSaga(),
        articleSaga(),
        paymentSaga(),
        commentSaga(),
        contestSaga(),
        configSaga(),
        bottomSheetSaga(),
        promotionSaga()
    ]);
}

export default fluvReducer;
