import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getPushes = ({ offset, limit, isReserve, userId, nickname, pushChannel, pushTarget, pushStatus }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/pushes/`, {
        params: {
            offset,
            limit,
            is_reserve: isReserve,
            user_id: userId,
            nickname,
            push_channel: pushChannel,
            push_target: pushTarget,
            status: pushStatus
        }
    });

export const postChannelPush = ({
    title,
    content,
    action,
    pushChannel,
    pushTarget,
    payload,
    isReserve,
    reserveTime,
    target
}) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/pushes/${target}/`, {
        title,
        content,
        action,
        push_channel: pushChannel,
        push_target: pushTarget,
        payload,
        is_reserve: isReserve,
        reserve_time: reserveTime
    });

export const postUserPush = ({
    title,
    content,
    action,
    targetUserList,
    pushTarget,
    payload,
    isReserve,
    reserveTime,
    target
}) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/pushes/${target}/`, {
        title,
        content,
        action,
        target_user_list: targetUserList,
        push_target: pushTarget,
        payload,
        is_reserve: isReserve,
        reserve_time: reserveTime
    });

export const cancelReservePush = ({ pushId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/pushes/${pushId}/`, {});
