/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    Routes - Marketing
*/
import { lazy } from 'react';

import Loadable from 'exodus/components/ui/load/Loadable';
import Unauthorized from 'exodus/components/unauthorized/Unauthorized';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData, isOperator } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';
import menuItem from 'fluv/menuItems';

const Notice = Loadable(lazy(() => import('fluv/pages/Marketing/Notice/Notice')));
const NoticeAdd = Loadable(lazy(() => import('fluv/pages/Marketing/Notice/NoticeAdd')));
const NoticeEdit = Loadable(lazy(() => import('fluv/pages/Marketing/Notice/NoticeEdit')));
const Event = Loadable(lazy(() => import('fluv/pages/Marketing/Event/Event')));
const EventAdd = Loadable(lazy(() => import('fluv/pages/Marketing/Event/EventAdd')));
const EventEdit = Loadable(lazy(() => import('fluv/pages/Marketing/Event/EventEdit')));
// const Banner = Loadable(lazy(() => import('fluv/pages/Marketing/Banner/Banner')));
// const BannerAdd = Loadable(lazy(() => import('fluv/pages/Marketing/Banner/BannerAdd')));
// const BannerEdit = Loadable(lazy(() => import('fluv/pages/Marketing/Banner/BannerEdit')));
const BottomSheet = Loadable(lazy(() => import('fluv/pages/Marketing/BottomSheet/BottomSheet')));
const BottomSheetAdd = Loadable(lazy(() => import('fluv/pages/Marketing/BottomSheet/BottomSheetAdd')));
const BottomSheetEdit = Loadable(lazy(() => import('fluv/pages/Marketing/BottomSheet/BottomSheetEdit')));
const Push = Loadable(lazy(() => import('fluv/pages/Marketing/Push/Push')));
// const ReservePush = Loadable(lazy(() => import('fluv/pages/Marketing/Push/ReservePush')));
const ChannelPush = Loadable(lazy(() => import('fluv/pages/Marketing/Push/Publish/ChannelPush')));
const UserPush = Loadable(lazy(() => import('fluv/pages/Marketing/Push/Publish/UserPush')));
const Config = Loadable(lazy(() => import('fluv/pages/Marketing/Config/Config')));
const ConfigEdit = Loadable(lazy(() => import('fluv/pages/Marketing/Config/ConfigEdit')));
// const Promotion = Loadable(lazy(() => import('fluv/pages/Marketing/Promotion/Promotion')));
// const PromotionEdit = Loadable(lazy(() => import('fluv/pages/Marketing/Promotion/PromotionEdit')));
const Reward = Loadable(lazy(() => import('fluv/pages/Marketing/Reward/Reward')));
const RewardHistory = Loadable(lazy(() => import('fluv/pages/Marketing/Reward/History')));
const Donation = Loadable(lazy(() => import('fluv/pages/Marketing/Donation')));
const CouponRewardHistory = Loadable(lazy(() => import('fluv/pages/Marketing/Coupon/RewardHistory/common')));
const CouponGenerator = Loadable(lazy(() => import('fluv/pages/Marketing/Coupon/Generator/common')));
const CouponUsageHistory = Loadable(lazy(() => import('fluv/pages/Marketing/Coupon/CouponUsageHistory/common')));
const CouponEditDetails = Loadable(lazy(() => import('fluv/pages/Marketing/Coupon/EditDetails/common')));

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}

const MarketingRoutes = {
    path: '/marketing/*',
    element: (
        <AuthGuard>
            <MainLayout menuItem={menuItem} />
        </AuthGuard>
    ),
    children: [
        {
            path: 'notice',
            exact: true,
            element: isOperator(role) ? <Notice /> : <Unauthorized role={role} />
        },
        {
            path: 'notice/add',
            exact: true,
            element: isOperator(role) ? <NoticeAdd /> : <Unauthorized role={role} />
        },
        {
            path: 'notice/edit/:noticeId',
            exact: true,
            element: isOperator(role) ? <NoticeEdit /> : <Unauthorized role={role} />
        },
        {
            path: 'event',
            exact: true,
            element: isOperator(role) ? <Event /> : <Unauthorized role={role} />
        },
        {
            path: 'event/add',
            exact: true,
            element: isOperator(role) ? <EventAdd /> : <Unauthorized role={role} />
        },
        {
            path: 'event/edit/:eventId',
            exact: true,
            element: isOperator(role) ? <EventEdit /> : <Unauthorized role={role} />
        },
        // deprecated
        // {
        //     path: 'banner',
        //     exact: true,
        //     element: <Banner />
        // },
        // {
        //     path: 'banner/add',
        //     exact: true,
        //     element: <BannerAdd />
        // },
        // {
        //     path: 'banner/edit/:bannerId',
        //     exact: true,
        //     element: <BannerEdit />
        // },

        // {
        //     path: 'promotion/',
        //     exact: true,
        //     element: <Promotion />
        // },
        // {
        //     path: 'promotion/:promotionId',
        //     exact: true,
        //     element: <PromotionEdit />
        // },
        {
            path: 'bottom-sheet',
            exact: true,
            element: <BottomSheet />
        },
        {
            path: 'bottom-sheet/add',
            exact: true,
            element: <BottomSheetAdd />
        },
        {
            path: 'bottom-sheet/edit/:bannerId',
            exact: true,
            element: <BottomSheetEdit />
        },
        {
            path: 'push',
            exact: true,
            element: <Push />
        },
        {
            path: 'push/publish/channel',
            exact: true,
            element: isOperator(role) ? <ChannelPush /> : <Unauthorized role={role} />
        },
        {
            path: 'push/publish/users',
            exact: true,
            element: isOperator(role) ? <UserPush /> : <Unauthorized role={role} />
        },
        {
            path: 'config',
            exact: true,
            element: <Config />
        },
        {
            path: 'config/edit/:configId',
            exact: true,
            element: <ConfigEdit />
        },
        {
            path: 'reward/history',
            exact: true,
            element: <RewardHistory />
        },
        {
            path: 'reward',
            exact: true,
            element: isOperator(role) ? <Reward /> : <Unauthorized role={role} />
        },
        {
            path: 'donations',
            exact: true,
            element: isOperator(role) ? <Donation /> : <Unauthorized role={role} />
        },

        {
            path: 'coupons',
            exact: true,
            element: isOperator(role) ? <CouponRewardHistory /> : <Unauthorized role={role} />
        },
        {
            path: 'coupons/:id',
            exact: true,
            element: <CouponUsageHistory />
        },
        {
            path: 'coupons/add',
            exact: true,
            element: isOperator(role) ? <CouponGenerator /> : <Unauthorized role={role} />
        },
        {
            path: 'coupons/edit/:id',
            exact: true,
            element: isOperator(role) ? <CouponEditDetails /> : <Unauthorized role={role} />
        },
        { path: '*', element: <NotFound /> }
    ]
};

export default MarketingRoutes;
