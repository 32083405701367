import PerfectScrollbar from 'react-perfect-scrollbar';

import { IconLogout, IconSettings } from '@tabler/icons';
import PropTypes from 'prop-types';

// material-ui

// third-party

// project imports
import MainCard from 'exodus/components/ui/cards/MainCard';
import Transitions from 'exodus/components/ui/extended/Transitions';

import {
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// assets

// ==============================|| PROFILE MENU ||============================== //

const Profile = ({
    theme,
    customization,
    selectedIndex,
    open,
    anchorRef,
    handleLogout,
    handleClose,
    handleListItemClick,
    handleToggle
}) => (
    <>
        <Chip
            sx={{
                height: '48px',
                alignItems: 'center',
                borderRadius: '27px',
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg': {
                        stroke: theme.palette.primary.light
                    }
                },
                '& .MuiChip-label': {
                    lineHeight: 0
                }
            }}
            label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
        />
        <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]
            }}
        >
            {({ TransitionProps }) => (
                <Transitions in={open} {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MainCard
                                border={false}
                                elevation={16}
                                content={false}
                                boxShadow
                                shadow={theme.shadows[16]}
                            >
                                <Box sx={{ p: 2, padding: '16px 16px 0px 16px' }}>
                                    <Stack>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                            <Typography variant="h4">Good Morning,</Typography>
                                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                ADMIN
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2">Project Admin</Typography>
                                    </Stack>

                                    <Divider />
                                </Box>
                                <PerfectScrollbar
                                    style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}
                                >
                                    <Box sx={{ p: 2, padding: '0px 16px 0px 16px' }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={event => handleListItemClick(event, 0, '/exodus/profile/self')}
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Typography variant="body2">프로필 관리</Typography>}
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Typography variant="body2">로그아웃</Typography>}
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </PerfectScrollbar>
                            </MainCard>
                        </ClickAwayListener>
                    </Paper>
                </Transitions>
            )}
        </Popper>
    </>
);

Profile.propTypes = {
    theme: PropTypes.object,
    customization: PropTypes.object,
    selectedIndex: PropTypes.number,
    open: PropTypes.bool,
    anchorRef: PropTypes.object,
    handleLogout: PropTypes.func,
    handleClose: PropTypes.func,
    handleListItemClick: PropTypes.func,
    handleToggle: PropTypes.func
};

export default Profile;
