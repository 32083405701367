import { useState, useRef, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui
import { logout } from 'store/modules/exodus/auth';

import Profile from 'exodus/components/main/component/header/profile/Profile';

import { useTheme } from '@mui/material/styles';

// ==============================|| PROFILE MENU ||============================== //

const ProfileContainer = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector(state => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        dispatch(logout());
        navigate('/auth/login', { replace: true });
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Profile
            theme={theme}
            customization={customization}
            selectedIndex={selectedIndex}
            open={open}
            anchorRef={anchorRef}
            handleLogout={handleLogout}
            handleClose={handleClose}
            handleListItemClick={handleListItemClick}
            handleToggle={handleToggle}
        />
    );
};

export default ProfileContainer;
