import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as contestAPI from 'fluv/lib/api/contestAPI';

// ==============================|| ACTIONS ||============================== //

const [GET_CONTEST_NOW, GET_CONTEST_NOW_SUCCESS, GET_CONTEST_NOW_FAILURE] =
    createRequestActionTypes('fluv/GET_CONTEST_NOW');
const [GET_CONTEST_NOW_MORE, GET_CONTEST_NOW_MORE_SUCCESS, GET_CONTEST_NOW_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_CONTEST_NOW_MORE');
const [GET_CONTEST_LIST, GET_CONTEST_LIST_SUCCESS, GET_CONTEST_LIST_FAILURE] =
    createRequestActionTypes('fluv/GET_CONTEST_LIST');
const [GET_CONTEST_DETAIL, GET_CONTEST_DETAIL_SUCCESS, GET_CONTEST_DETAIL_FAILURE] =
    createRequestActionTypes('fluv/GET_CONTEST_DETAIL');
const [GET_CONTEST_DETAIL_MORE, GET_CONTEST_DETAIL_MORE_SUCCESS, GET_CONTEST_DETAIL_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_CONTEST_DETAIL_MORE');

export const getContestNow = createAction(GET_CONTEST_NOW, ({ offset, limit }) => ({
    offset,
    limit
}));

export const getContestNowMore = createAction(GET_CONTEST_NOW_MORE, ({ offset, limit }) => ({
    offset,
    limit
}));

export const getContestList = createAction(GET_CONTEST_LIST, () => ({}));

export const getContestDetail = createAction(GET_CONTEST_DETAIL, ({ offset, limit, contestId }) => ({
    offset,
    limit,
    contestId
}));

export const getContestDetailMore = createAction(GET_CONTEST_DETAIL_MORE, ({ offset, limit, contestId }) => ({
    offset,
    limit,
    contestId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    contestNow: {
        ranker: null,
        contest: {
            id: 0,
            start_date: '',
            end_date: ''
        },
        offset: 0,
        limit: 20,
        getMore: true
    },
    contestList: {
        data: [],
        status: 'ready'
    },
    contestDetail: {
        data: null,
        offset: 0,
        limit: 20,
        getMore: false
    },
    contestChallengerRecord: {
        data: null,
        offset: 0,
        limit: 20,
        getMore: true
    }
};

// ==============================|| SAGA ||============================== //

// contest saga
const getContestNowSaga = createRequestSaga(GET_CONTEST_NOW, contestAPI.getContestNow);
const getContestNowMoreSaga = createRequestSaga(GET_CONTEST_NOW_MORE, contestAPI.getContestNow);
const getContestListSaga = createRequestSaga(GET_CONTEST_LIST, contestAPI.getContestList);
const getContestDetailSaga = createRequestSaga(GET_CONTEST_DETAIL, contestAPI.getContestDetail);
const getContestDetailMoreSaga = createRequestSaga(GET_CONTEST_DETAIL_MORE, contestAPI.getContestDetail);

export function* contestSaga() {
    // contest
    yield takeLatest(GET_CONTEST_NOW, getContestNowSaga);
    yield takeLatest(GET_CONTEST_NOW_MORE, getContestNowMoreSaga);
    yield takeLatest(GET_CONTEST_LIST, getContestListSaga);
    yield takeLatest(GET_CONTEST_DETAIL, getContestDetailSaga);
    yield takeLatest(GET_CONTEST_DETAIL_MORE, getContestDetailMoreSaga);
}

const contest = handleActions(
    {
        [GET_CONTEST_NOW_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            contestNow: {
                ...state.contestNow,
                ranker: data.data.ranker,
                contest: data.data.contest,
                offset: initialState.contestNow.offset,
                getMore: Object.keys(data.data.ranker).length === state.contestNow.limit
            }
        }),
        [GET_CONTEST_NOW_FAILURE]: state => ({
            ...state,
            contestNow: {
                ...state,
                ranker: 'error',
                contest: initialState.contestNow.contest
            }
        }),
        [GET_CONTEST_NOW_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            contestNow: {
                ...state.contestNow,
                ranker: state.contestNow.ranker.concat(data.data.ranker),
                contest: data.data.contest,
                offset: state.contestNow.offset + state.contestNow.limit,
                getMore: Object.keys(data.data.ranker).length === state.contestNow.limit
            }
        }),
        [GET_CONTEST_NOW_MORE_FAILURE]: state => ({
            ...state,
            contestNow: {
                ...state
            }
        }),
        [GET_CONTEST_LIST_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            contestList: {
                ...state.contestList,
                data: data.data,
                status: 'done'
            }
        }),
        [GET_CONTEST_LIST_FAILURE]: state => ({
            ...state,
            contestList: {
                ...state.contestList,
                data: 'error',
                status: 'error'
            }
        }),
        [GET_CONTEST_DETAIL_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            contestDetail: {
                ...state.contestDetail,
                data: data.data.ranker,
                offset: initialState.contestDetail.offset,
                getMore: Object.keys(data.data.ranker).length === state.contestDetail.limit
            }
        }),
        [GET_CONTEST_DETAIL_FAILURE]: state => ({
            ...state,
            contestDetail: initialState.contestDetail
        }),
        [GET_CONTEST_DETAIL_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            contestDetail: {
                ...state.contestDetail,
                data: state.contestDetail.data.concat(data.data.ranker),
                offset: state.contestDetail.offset + state.contestDetail.limit,
                getMore: Object.keys(data.data.ranker).length === state.contestDetail.limit
            }
        }),
        [GET_CONTEST_DETAIL_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default contest;
