import { createAction, handleActions } from 'redux-actions';
// ==============================|| ACTIONS ||============================== //

export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const FLUV_SNACKBAR_OPEN = '@snackbar/FLUV_SNACKBAR_OPEN';

export const openSnackBar = createAction(
    SNACKBAR_OPEN,
    ({ open, message, anchorOrigin, variant, alertSeverity, transition, close, actionButton }) => ({
        open,
        message,
        anchorOrigin,
        variant,
        alertSeverity,
        transition,
        close,
        actionButton
    })
);

export const openFluvSnackBar = createAction(FLUV_SNACKBAR_OPEN, ({ alertSeverity, message }) => ({
    alertSeverity,
    message
}));

// ==============================|| STATE ||============================== //

const initialState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: 'default',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

// ==============================|| REDUCER ||============================== //

const snackbar = handleActions(
    {
        [SNACKBAR_OPEN]: (state, { payload: action }) => ({
            ...state,
            action: !state.action,
            open: action.open ? action.open : initialState.open,
            message: action.message ? action.message : initialState.message,
            anchorOrigin: action.anchorOrigin ? action.anchorOrigin : initialState.anchorOrigin,
            variant: action.variant ? action.variant : initialState.variant,
            alertSeverity: action.alertSeverity ? action.alertSeverity : initialState.alertSeverity,
            transition: action.transition ? action.transition : initialState.transition,
            close: action.close === false ? action.close : initialState.close,
            actionButton: action.actionButton ? action.actionButton : initialState.actionButton
        }),
        [FLUV_SNACKBAR_OPEN]: (state, { payload: { alertSeverity, message } }) => ({
            ...state,
            action: !state.action,
            open: true,
            message,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'alert',
            alertSeverity,
            transition: 'SlideLeft',
            close: 'true',
            actionButton: initialState.actionButton
        })
    },
    initialState
);

export default snackbar;
