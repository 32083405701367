import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as configAPI from 'fluv/lib/api/configAPI';

// ==============================|| ACTIONS ||============================== //

const [GET_CONFIG, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE] = createRequestActionTypes('fluv/GET_CONFIG');
const [GET_CONFIG_MORE, GET_CONFIG_MORE_SUCCESS, GET_CONFIG_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_CONFIG_MORE');

export const getConfig = createAction(
    GET_CONFIG,
    ({ offset, limit, userId, targetId, userNickname, targetNickname }) => ({
        offset,
        limit,
        userId,
        targetId,
        userNickname,
        targetNickname
    })
);

export const getConfigMore = createAction(
    GET_CONFIG_MORE,
    ({ offset, limit, userId, targetId, userNickname, targetNickname }) => ({
        offset,
        limit,
        userId,
        targetId,
        userNickname,
        targetNickname
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    configList: null,
    offset: 0,
    limit: 20,
    getMore: true
};

// ==============================|| SAGA ||============================== //

const getConfigSaga = createRequestSaga(GET_CONFIG, configAPI.getConfig);
const getConfigMoreSaga = createRequestSaga(GET_CONFIG_MORE, configAPI.getConfig);

export function* configSaga() {
    yield takeLatest(GET_CONFIG, getConfigSaga);
    yield takeLatest(GET_CONFIG_MORE, getConfigMoreSaga);
}

// ==============================|| REDUCER ||============================== //

const config = handleActions(
    {
        [GET_CONFIG_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            configList: data.data,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_CONFIG_FAILURE]: state => ({
            ...state,
            configList: 'error'
        }),
        [GET_CONFIG_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            configList: state.configList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_CONFIG_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default config;
