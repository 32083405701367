// third-party
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

// project import
import * as authAPI from 'exodus/lib/api/auth';
import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import { encryptTimestampWithAdmin, getCurrentTimestamp } from 'exodus/utils/role';

// ==============================|| ACTIONS ||============================== //

const UPDATE_LOGIN_STATUS = 'auth/UPDATE_LOGIN_STATUS';

const LOGOUT = 'auth/LOGOUT';

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');

const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createRequestActionTypes('auth/REGISTER');

export const userLogin = createAction(LOGIN, ({ email, password }) => ({
    email,
    password
}));

export const userRegister = createAction(REGISTER, ({ email, nickname, password1, password2 }) => ({
    email,
    nickname,
    password1,
    password2
}));

export const logout = createAction(LOGOUT);

export const updateLoginStatus = createAction(UPDATE_LOGIN_STATUS, isLoggedIn => ({ isLoggedIn }));

// ==============================|| STATE ||============================== //

export const initialState = {
    error: {
        type: null,
        description: null
    },
    result: '',
    user: null,
    isLoggedIn: false,
    role: null
};

// ==============================|| SAGA ||============================== //

// saga 생성
const userLoginSaga = createRequestSaga(LOGIN, authAPI.userLogin);
const userRegisterSaga = createRequestSaga(REGISTER, authAPI.userRegister);

export function* authSaga() {
    yield takeLatest(LOGIN, userLoginSaga);
    yield takeLatest(REGISTER, userRegisterSaga);
}

// ==============================|| REDUCER ||============================== //

const auth = handleActions(
    {
        [LOGIN_SUCCESS]: (state, { payload: data }) => {
            const timestamp = getCurrentTimestamp();
            const r = encryptTimestampWithAdmin(timestamp, data.data.user.role);
            window.localStorage.setItem('flirting-admin-role', r);
            return {
                ...state,
                error: initialState.error,
                user: data.data.user,
                isLoggedIn: true,
                role: data.data.user.role
            };
        },
        [LOGIN_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error: {
                type: 'login',
                description: error.data.gcode === 3008 ? '미인증 계정입니다. 담당자에게 문의해주세요.' : error.data.msg
            }
        }),
        [REGISTER_SUCCESS]: state => ({
            ...state,
            error: initialState.error,
            result: 'SUCCESS',
            isLoggedIn: false
        }),
        [REGISTER_FAILURE]: (state, { payload: error }) => ({
            ...state,
            result: 'FAIL',
            error: {
                type: 'register',
                description: error.data.msg
            }
        }),
        [UPDATE_LOGIN_STATUS]: (state, { payload: { isLoggedIn } }) => ({
            ...state,
            isLoggedIn
        }),
        [LOGOUT]: state => {
            window.localStorage.removeItem('flirting-admin-role');
            return {
                ...state,
                error: initialState.error,
                user: initialState.user,
                isLoggedIn: false,
                role: null
            };
        }
    },
    initialState
);

export default auth;
