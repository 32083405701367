const ViewableOffset = [
    {
        value: '',
        label: '공개 여부',
        form: 'viewable'
    },
    {
        value: 'True',
        label: '공개',
        form: 'viewable'
    },
    {
        value: 'False',
        label: '비공개',
        form: 'viewable'
    }
];

export default ViewableOffset;
