import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getNotices = ({ offset, limit, isViewable, search, locale }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/`, {
        params: { offset, limit, is_viewable: isViewable, search, type: 'notice', locale }
    });

export const getNoticeDetail = ({ noticeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${noticeId}/`, {});

export const postNotice = ({ title, content, priority, isViewable, locale, publishedAt }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/`, {
        title,
        content,
        priority,
        is_viewable: isViewable,
        type: 'notice',
        locale,
        published_at: publishedAt
    });

export const putNotice = ({ noticeId, title, content, priority, isViewable, locale, publishedAt }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${noticeId}/`, {
        noticeId,
        title,
        content,
        priority,
        is_viewable: isViewable,
        type: 'notice',
        locale,
        published_at: publishedAt
    });

export const deleteNotice = ({ noticeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${noticeId}/`, { noticeId });

export const postPresignedUrl = ({ ext }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/presigned_url/`, {
        ext
    });
