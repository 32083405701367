/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    Channel API
*/
import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

// Channel API
export const getChannels = ({ cursor, limit, category, nickname, userId, channelId, type }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/`, {
        params: { cursor, limit, category, nickname, user_id: userId, channel_id: channelId, type }
    });

export const getChannelDetail = ({ channelId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/${channelId}/`, {});

export const getChannelFollower = ({ channelId, cursor, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/${channelId}/followers/`, {
        params: { cursor, limit }
    });

export const putChannelType = ({ channelId, type }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/${channelId}/type/`, {
        type
    });

export const putChannelBuff = ({ channelId, weight }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/${channelId}/buff/`, {
        weight
    });

// Channel Sub Page API
export const getChannelArticle = ({ cursor, limit, channelId }) =>
    // Article Pagination 수정 시 변경 예정
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/`, {
        params: { cursor, limit, channel_id: channelId }
    });

export const getChannelRank = () => authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/rank/`, {});

export const getChannelVoteToday = ({ cursor, limit, channelId, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/vote/today/`, {
        params: { cursor, limit, channel_id: channelId, user_id: userId }
    });

export const getChannelVoteHistory = ({ cursor, limit, channelId, userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/vote/history/`, {
        params: { cursor, limit, channel_id: channelId, user_id: userId }
    });

// Channel Report API
export const getReport = ({ cursor, limit, isChecked }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/`, {
        params: { cursor, limit, is_checked: isChecked }
    });

export const getReportDetail = ({ reportId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/${reportId}/`, {});

export const putReport = ({ reportId, isChecked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/${reportId}/`, {
        is_checked: isChecked
    });

export const getChannelReport = ({ channelId, cursor, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/${channelId}/reports/`, { params: { cursor, limit } });

// Channel Report Type API
export const getReportType = ({ cursor, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/type/`, {
        params: { cursor, limit, is_viewable: isViewable }
    });

export const postReportType = ({ priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/type/`, {
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putReportType = ({ reportTypeId, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/type/${reportTypeId}/`, {
        reportTypeId,
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteReportType = ({ reportTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/channels/report/type/${reportTypeId}/`, {});
