import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as userAPI from 'fluv/lib/api/userAPI';

import BlockOffset from 'fluv/utils/Offset/BlockOffset';
import DeviceOffset from 'fluv/utils/Offset/DeviceOffset';
import EmailCheckedOffset from 'fluv/utils/Offset/EmailCheckedOffset';
import LocaleOffset from 'fluv/utils/Offset/LocaleOffset';
import RoleOffset from 'fluv/utils/Offset/RoleOffset';
import StatusOffset from 'fluv/utils/Offset/StatusOffset';
import SuperuserOffset from 'fluv/utils/Offset/SuperuserOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_USER';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_USER';
const [GET_USER, GET_USER_SUCCESS, GET_USER_FAILURE] = createRequestActionTypes('fluv/GET_USER');
const [GET_USER_MORE, GET_USER_MORE_SUCCESS, GET_USER_MORE_FAILURE] = createRequestActionTypes('fluv/GET_USER_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getUser = createAction(
    GET_USER,
    ({
        cursor,
        limit,
        locale,
        emailChecked,
        device,
        status,
        userId,
        nickname,
        role,
        email,
        isBlocked,
        isSuperuser
    }) => ({
        cursor,
        limit,
        locale,
        emailChecked,
        device,
        status,
        userId,
        nickname,
        role,
        email,
        isBlocked,
        isSuperuser
    })
);

export const getUserMore = createAction(
    GET_USER_MORE,
    ({
        cursor,
        limit,
        locale,
        emailChecked,
        device,
        status,
        userId,
        nickname,
        role,
        email,
        isBlocked,
        isSuperuser
    }) => ({
        cursor,
        limit,
        locale,
        emailChecked,
        device,
        status,
        userId,
        nickname,
        role,
        email,
        isBlocked,
        isSuperuser
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    userList: null,
    filter: {
        block: {
            value: '',
            label: '차단 여부',
            form: 'block',
            offset: BlockOffset
        },
        locale: {
            value: '',
            label: '언어',
            offset: LocaleOffset
        },
        emailChecked: {
            value: '',
            label: '인증 여부',
            form: 'emailChecked',
            offset: EmailCheckedOffset
        },
        device: {
            value: '',
            label: '디바이스',
            form: 'device',
            offset: DeviceOffset
        },
        status: {
            value: '',
            label: '상태',
            form: 'status',
            offset: StatusOffset
        },
        role: {
            value: '',
            label: '역할',
            form: 'role',
            offset: RoleOffset
        },
        superuser: {
            value: '',
            label: '유저 권한',
            form: 'superuser',
            offset: SuperuserOffset
        }
    },
    search: {
        input: '',
        target: 'email'
    },
    cursor: null,
    limit: 20,
    getMore: true
};

// ==============================|| SAGA ||============================== //

// user saga
const getUsersSaga = createRequestSaga(GET_USER, userAPI.getUser);
const getUsersMoreSaga = createRequestSaga(GET_USER_MORE, userAPI.getUser);

export function* userSaga() {
    // user
    yield takeLatest(GET_USER, getUsersSaga);
    yield takeLatest(GET_USER_MORE, getUsersMoreSaga);
}

const user = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_USER_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            userList: data.data.objects,
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_USER_FAILURE]: state => ({
            ...state,
            userList: 'error'
        }),
        [GET_USER_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            userList: state.userList.concat(data.data.objects),
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_USER_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default user;
