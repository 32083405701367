/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    Channel Type Offset
*/
const ChannelTypeOffset = [
    {
        value: '',
        label: '종류',
        form: 'channelType'
    },
    {
        value: 'N',
        label: '일반 채널',
        form: 'channelType'
    },
    {
        value: 'F',
        label: '팬계정 채널',
        form: 'channelType'
    }
];

export default ChannelTypeOffset;
