/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>

    MenuItems - CS
*/

import {
    IconFileTypography,
    IconMicrophone2,
    IconPlayerPlay,
    IconPlaylist,
    IconPlaylistAdd,
    IconQuestionMark,
    IconSignature,
    IconUser,
    IconZoomQuestion
} from '@tabler/icons';

const icons = {
    IconUser,
    IconMicrophone2,
    IconPlaylist,
    IconPlaylistAdd,
    IconFileTypography,
    IconSignature,
    IconZoomQuestion,
    IconQuestionMark,
    IconPlayerPlay
};

const contents = {
    id: 'contents',
    title: 'CONTENTS',
    type: 'group',
    children: [
        {
            id: 'artists',
            title: '아티스트',
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'artist',
                    title: '아티스트 관리',
                    type: 'item',
                    url: '/fillit/contents/artists/all',
                    icon: icons.IconMicrophone2,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'songs',
            title: '노래',
            type: 'collapse',
            icon: icons.IconPlaylist,
            children: [
                {
                    id: 'song',
                    title: '노래 관리',
                    type: 'item',
                    url: '/fillit/contents/songs/all',
                    icon: icons.IconPlaylistAdd,
                    breadcrumbs: true
                },
                {
                    id: 'play',
                    title: '플레이 기록',
                    type: 'item',
                    url: '/fillit/contents/plays/all',
                    icon: icons.IconPlayerPlay,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'quizzes',
            title: '퀴즈',
            type: 'collapse',
            icon: icons.IconQuestionMark,
            children: [
                {
                    id: 'quiz',
                    title: '퀴즈 관리',
                    type: 'item',
                    url: '/fillit/contents/quizzes/all',
                    icon: icons.IconMicrophone2,
                    breadcrumbs: true
                },
                {
                    id: 'play',
                    title: '플레이 기록',
                    type: 'item',
                    url: '/fillit/contents/quizzes/plays/all',
                    icon: icons.IconPlayerPlay,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default contents;
