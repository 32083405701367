import { useRoutes } from 'react-router-dom';

import CSRoutes from 'idol/routes/CSRoutes';
import DashboardRoutes from 'idol/routes/DashboardRoutes';

import NotFound from 'exodus/pages/NotFound';

export default function IdolRoutes() {
    return useRoutes([DashboardRoutes, CSRoutes, { path: '*', element: <NotFound /> }]);
}
