import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as paymentAPI from 'fluv/lib/api/paymentAPI';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_PAYMENT_EXCHANGE';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_PAYMENT_EXCHANGE';
const [GET_PAYMENT_EXCHANGE, GET_PAYMENT_EXCHANGE_SUCCESS, GET_PAYMENT_EXCHANGE_FAILURE] =
    createRequestActionTypes('fluv/GET_PAYMENT_EXCHANGE');
const [GET_PAYMENT_EXCHANGE_MORE, GET_PAYMENT_EXCHANGE_MORE_SUCCESS, GET_PAYMENT_EXCHANGE_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_PAYMENT_EXCHANGE_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getPaymentExchange = createAction(GET_PAYMENT_EXCHANGE, ({ offset, limit, isViewable }) => ({
    offset,
    limit,
    isViewable
}));

export const getPaymentExchangeMore = createAction(GET_PAYMENT_EXCHANGE_MORE, ({ offset, limit, isViewable }) => ({
    offset,
    limit,
    isViewable
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    exchangeList: null,
    offset: 0,
    limit: 20,
    getMore: true,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        }
    }
};

// ==============================|| SAGA ||============================== //

// exchange saga
const getPaymentExchangeSaga = createRequestSaga(GET_PAYMENT_EXCHANGE, paymentAPI.getExchange);
const getPaymentExchangeMoreSaga = createRequestSaga(GET_PAYMENT_EXCHANGE_MORE, paymentAPI.getExchange);

export function* exchangeSaga() {
    yield takeLatest(GET_PAYMENT_EXCHANGE, getPaymentExchangeSaga);
    yield takeLatest(GET_PAYMENT_EXCHANGE_MORE, getPaymentExchangeMoreSaga);
}

const exchange = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_PAYMENT_EXCHANGE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            exchangeList: data.data,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PAYMENT_EXCHANGE_FAILURE]: state => ({
            ...state,
            exchangeList: 'error'
        }),
        [GET_PAYMENT_EXCHANGE_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            exchangeList: state.exchangeList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PAYMENT_EXCHANGE_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default exchange;
