const CheckedOffset = [
    {
        value: '',
        label: '확인 여부',
        form: 'checked'
    },
    {
        value: 'True',
        label: '확인 완료',
        form: 'checked'
    },
    {
        value: 'False',
        label: '확인 미완료',
        form: 'checked'
    }
];

export default CheckedOffset;
