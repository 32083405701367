/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>

    MenuItems - CS
*/

/* eslint-disable no-unused-vars */
import { IconAlarm, IconFlag, IconPhoneCall, IconQuestionMark } from '@tabler/icons';

const icons = { IconAlarm, IconPhoneCall, IconFlag, IconQuestionMark };

const cs = {
    id: 'cs',
    title: 'C/S',
    type: 'group',
    children: [
        {
            id: 'inquiry',
            title: '문의/신고',
            type: 'collapse',
            icon: icons.IconPhoneCall,
            children: [
                {
                    id: 'inquiry',
                    title: '문의/답변 관리',
                    type: 'item',
                    url: '/fillit/cs/inquiry',
                    icon: icons.IconPhoneCall,
                    breadcrumbs: true
                },
                {
                    id: 'inquiryType',
                    title: 'FAQ 관리',
                    type: 'item',
                    url: '/fillit/cs/faq',
                    icon: icons.IconQuestionMark,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default cs;
