import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

// material-ui
import idol from 'exodus/assets/images/exodus/icon_sns_choeaedol.png';

import { Avatar, Box, ButtonBase } from '@mui/material';

// third-party
// project imports
// assets

// notification status options

// ==============================|| NOTIFICATION ||============================== //

const Idol = ({ theme }) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/idol/dashboard/default', { push: true });
    };
    return (
        <Box
            sx={{
                ml: 2,
                [theme.breakpoints.down('md')]: {
                    mr: 1
                }
            }}
        >
            <ButtonBase sx={{ borderRadius: '12px' }}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background:
                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color:
                            theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.warning.dark
                                    : theme.palette.secondary.dark,
                            color:
                                theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    aria-haspopup="true"
                    onClick={onClick}
                    color="inherit"
                >
                    <img src={idol} alt="idol" width="18" />
                </Avatar>
            </ButtonBase>
        </Box>
    );
};

Idol.propTypes = {
    theme: PropTypes.object
};

export default Idol;
