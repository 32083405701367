import Celeb from 'exodus/components/main/component/header/services/Celeb';
import Exodus from 'exodus/components/main/component/header/services/Exodus';
import FILLIT from 'exodus/components/main/component/header/services/Fillit';
import Fluv from 'exodus/components/main/component/header/services/Fluv';
import Idol from 'exodus/components/main/component/header/services/Idol';

import { useTheme } from '@mui/material/styles';

const ServiceContainer = () => {
    const theme = useTheme();

    return (
        <>
            <Exodus theme={theme} />
            <Idol theme={theme} />
            <Celeb theme={theme} />
            <Fluv theme={theme} />
            <FILLIT theme={theme} />
        </>
    );
};

export default ServiceContainer;
