import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as paymentAPI from 'fluv/lib/api/paymentAPI';
import SubscribableOffset from 'fluv/utils/Offset/SubscribableOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_PAYMENT_STORE';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_PAYMENT_STORE';
const [GET_PAYMENT_STORE, GET_PAYMENT_STORE_SUCCESS, GET_PAYMENT_STORE_FAILURE] =
    createRequestActionTypes('fluv/GET_PAYMENT_STORE');
const [GET_PAYMENT_STORE_MORE, GET_PAYMENT_STORE_MORE_SUCCESS, GET_PAYMENT_STORE_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_PAYMENT_STORE_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getPaymentStore = createAction(GET_PAYMENT_STORE, ({ offset, limit, isViewable, isSubscribable }) => ({
    offset,
    limit,
    isViewable,
    isSubscribable
}));

export const getPaymentStoreMore = createAction(
    GET_PAYMENT_STORE_MORE,
    ({ offset, limit, isViewable, isSubscribable }) => ({
        offset,
        limit,
        isViewable,
        isSubscribable
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    storeList: null,
    offset: 0,
    limit: 20,
    getMore: true,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        subscribable: {
            value: '',
            label: '구독 여부',
            offset: SubscribableOffset
        }
    }
};

// ==============================|| SAGA ||============================== //

// store saga
const getPaymentStoreSaga = createRequestSaga(GET_PAYMENT_STORE, paymentAPI.getStore);
const getPaymentStoreMoreSaga = createRequestSaga(GET_PAYMENT_STORE_MORE, paymentAPI.getStore);

export function* storeSaga() {
    yield takeLatest(GET_PAYMENT_STORE, getPaymentStoreSaga);
    yield takeLatest(GET_PAYMENT_STORE_MORE, getPaymentStoreMoreSaga);
}

const store = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_PAYMENT_STORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            storeList: data.data,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PAYMENT_STORE_FAILURE]: state => ({
            ...state,
            storeList: 'error'
        }),
        [GET_PAYMENT_STORE_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            storeList: state.storeList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_PAYMENT_STORE_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default store;
