import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getReport = ({ target, nickname, cursor, limit, isChecked, reportTypeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/`, {
        params: { cursor, limit, is_checked: isChecked, report_type_id: reportTypeId, nickname }
    });

export const getReportDetail = ({ target, reportId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/${reportId}/`, {});

export const putReport = ({ target, reportId, isChecked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/${reportId}/`, {
        is_checked: isChecked
    });

export const putBlock = ({ target, targetId, isBlocked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/block/${targetId}/`, {
        is_blocked: isBlocked
    });
