import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as reportAPI from 'fluv/lib/api/report/reportAPI';
import CheckedOffset from 'fluv/utils/Offset/CheckedOffset';
import ReportOffset from 'fluv/utils/Offset/ReportOffset';

const CHANGE_FILTER = 'fluv/CHANGE_FILTER_REPORT';
const [GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAILURE] = createRequestActionTypes('fluv/GET_REPORT');
const [GET_REPORT_MORE, GET_REPORT_MORE_SUCCESS, GET_REPORT_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_REPORT_MORE');

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getReport = createAction(GET_REPORT, ({ target, offset, limit, isChecked }) => ({
    target,
    offset,
    limit,
    isChecked
}));

export const getReportMore = createAction(GET_REPORT_MORE, ({ target, offset, limit, isChecked }) => ({
    target,
    offset,
    limit,
    isChecked
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    reportList: null,
    filter: {
        checked: {
            value: '',
            label: '확인 여부',
            form: 'checked',
            offset: CheckedOffset
        },
        type: {
            value: 'articles',
            label: '게시물',
            form: 'type',
            offset: ReportOffset
        }
    },
    offset: 0,
    limit: 20,
    getMore: true
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getReportSaga = createRequestSaga(GET_REPORT, reportAPI.getReport);
const getReportMoreSaga = createRequestSaga(GET_REPORT_MORE, reportAPI.getReport);

export function* reportSaga() {
    yield takeLatest(GET_REPORT, getReportSaga);
    yield takeLatest(GET_REPORT_MORE, getReportMoreSaga);
}

// ==============================|| REDUCER ||============================== //

const report = handleActions(
    {
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_REPORT_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reportList: data.data.objects,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_REPORT_FAILURE]: state => ({
            ...state,
            reportList: 'error'
        }),
        [GET_REPORT_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reportList: state.reportList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_REPORT_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default report;
