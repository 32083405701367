import { useState, useRef, useEffect } from 'react';

// material-ui
import Mobile from 'exodus/components/main/component/header/mobile/Mobile';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ==============================|| MOBILE HEADER ||============================== //

const MobileContainer = () => {
    const theme = useTheme();
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Mobile
            theme={theme}
            matchMobile={matchMobile}
            open={open}
            anchorRef={anchorRef}
            handleToggle={handleToggle}
            handleClose={handleClose}
        />
    );
};

export default MobileContainer;
