import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getContestNow = ({ offset, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/contests/now/`, {
        params: {
            offset,
            limit
        }
    });

export const getContestList = () => authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/contests/`, {});

export const getContestDetail = ({ offset, limit, contestId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/contests/${contestId}/`, {
        params: {
            offset,
            limit
        }
    });

export const getChallengerContestRecord = ({ offset, limit, channelId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/contests/detail/${channelId}/`, {
        params: {
            offset,
            limit
        }
    });
