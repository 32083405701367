import cs from './cs';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
    items: [dashboard, cs]
};

export default MenuItems;
