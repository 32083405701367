import { useTheme } from '@mui/material/styles';

const ExodusLogo = () => {
    const theme = useTheme();

    return (
        // <img src={theme.palette.mode === 'dark' ? logo : logo} alt="exodus" height="32" />
        <svg width="180" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 32">
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M40.33,15.05v3.81h6.14V22.4H40.33v4.13h7V30.2H35.75V11.38H47.28v3.67Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M62,30.2l-3.83-5.76L54.75,30.2h-5.2l6-9.57-6.16-9.25h5.33l3.78,5.68,3.33-5.68h5.2l-6,9.49L67.3,30.2Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M71.85,29.16a9.25,9.25,0,0,1-3.52-3.45,9.67,9.67,0,0,1-1.3-5,9.61,9.61,0,0,1,1.3-5,9.3,9.3,0,0,1,3.52-3.44,10.24,10.24,0,0,1,9.73,0,9.08,9.08,0,0,1,3.49,3.44,9.69,9.69,0,0,1,1.27,5,9.67,9.67,0,0,1-1.29,5,9.15,9.15,0,0,1-3.48,3.45,10.22,10.22,0,0,1-9.72,0Zm8.47-4.45a5.74,5.74,0,0,0,1.36-4,5.73,5.73,0,0,0-1.36-4,4.63,4.63,0,0,0-3.61-1.48,4.69,4.69,0,0,0-3.63,1.47,5.7,5.7,0,0,0-1.35,4,5.7,5.7,0,0,0,1.35,4,4.67,4.67,0,0,0,3.63,1.49A4.61,4.61,0,0,0,80.32,24.71Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M100.69,12.56a8.38,8.38,0,0,1,3.44,3.31,9.66,9.66,0,0,1,1.22,4.92,9.7,9.7,0,0,1-1.22,4.91A8.33,8.33,0,0,1,100.67,29a10.92,10.92,0,0,1-5.19,1.18h-7V11.38h7A10.94,10.94,0,0,1,100.69,12.56ZM99.24,24.81a5.41,5.41,0,0,0,1.45-4,5.51,5.51,0,0,0-1.45-4.05,5.47,5.47,0,0,0-4-1.45H93V26.23h2.17A5.54,5.54,0,0,0,99.24,24.81Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M111.94,11.38V22.64a3.69,3.69,0,0,0,.83,2.6,3.13,3.13,0,0,0,2.44.91,3.24,3.24,0,0,0,2.47-.91,3.65,3.65,0,0,0,.85-2.6V11.38h4.59V22.61a8.05,8.05,0,0,1-1.07,4.27,6.83,6.83,0,0,1-2.89,2.62,9,9,0,0,1-4,.89,8.82,8.82,0,0,1-4-.87,6.52,6.52,0,0,1-2.78-2.63,8.4,8.4,0,0,1-1-4.28V11.38Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M128.91,29.72a6,6,0,0,1-2.61-2,5.39,5.39,0,0,1-1-3.16h4.88a2.43,2.43,0,0,0,.73,1.6,2.36,2.36,0,0,0,1.61.55,2.55,2.55,0,0,0,1.61-.47,1.59,1.59,0,0,0,.59-1.3,1.57,1.57,0,0,0-.47-1.16,4,4,0,0,0-1.16-.75,19.5,19.5,0,0,0-1.94-.67,21.16,21.16,0,0,1-3-1.12,5.44,5.44,0,0,1-2-1.67,4.59,4.59,0,0,1-.83-2.87,4.92,4.92,0,0,1,1.9-4.11,7.83,7.83,0,0,1,5-1.49,7.93,7.93,0,0,1,5,1.49,5.31,5.31,0,0,1,2,4.14h-5a1.91,1.91,0,0,0-.67-1.43,2.36,2.36,0,0,0-1.58-.53,2,2,0,0,0-1.34.45,1.7,1.7,0,0,0,.35,2.69,13.63,13.63,0,0,0,2.68,1.1,23.53,23.53,0,0,1,3,1.18,5.61,5.61,0,0,1,2,1.64,4.32,4.32,0,0,1,.84,2.76,5.47,5.47,0,0,1-.82,2.92,5.74,5.74,0,0,1-2.38,2.09,8.07,8.07,0,0,1-3.67.78A9.61,9.61,0,0,1,128.91,29.72Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M146.71,20.11v2.47h4v2.29h-4v2.67h4.5v2.38h-7.47V17.73h7.47v2.38Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M164,29.92h-3l-5-7.52v7.52h-3V17.73h3l5,7.55V17.73h3Z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                d="M175,17.73v2.38h-3.23v9.81h-3V20.11h-3.22V17.73Z"
            />
            <path
                fill="#34599d"
                d="M10.6,4a6.46,6.46,0,0,1,6.46,6.46V22.24a1.64,1.64,0,0,0,3.28.09V10.25a9.74,9.74,0,1,0-19.47,0V29.84h0a1.64,1.64,0,1,0,3.27,0V10.42A6.46,6.46,0,0,1,10.6,4Z"
            />
            <path
                fill="#34599d"
                d="M29.11,14.75a9.87,9.87,0,0,0-2.49-1.81l0,0-.13-.06h0a1.7,1.7,0,0,0-.64-.13A1.68,1.68,0,0,0,25,15.86l.15.08a6.46,6.46,0,0,1-3.05,12.15H12.24V9.81A1.64,1.64,0,0,0,9,9.73a.22.22,0,0,0,0,.08v0c0,.5,0,1,0,1.43V31.09H9v.28H22.22a9.74,9.74,0,0,0,6.89-16.62Z"
            />
        </svg>
    );
};

export default ExodusLogo;
