const DeviceOffset = [
    {
        value: '',
        label: '디바이스',
        form: 'device'
    },
    {
        value: 'android',
        label: 'ANDROID',
        form: 'device'
    },
    {
        value: 'iOS',
        label: 'iOS',
        form: 'device'
    }
];

export default DeviceOffset;
