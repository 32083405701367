/*
    Copyright (C) 2023 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    Unauthorized Component
*/
import { gridSpacing } from 'store/constant';

import { Card, CardContent, Grid, useTheme } from '@mui/material';

const Unauthorized = ({ role }) => {
    const theme = useTheme();

    return (
        <Card
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '22px',
                fontWeight: 'bold',
                lineHeight: '3.5rem',
                textAlign: 'center'
            }}
        >
            <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {role === undefined
                            ? '로그인 정보 업데이트를 위해 새로고침 후 다시 이용해주세요'
                            : '권한이 없습니다.'}
                        <div style={{ color: theme.palette.secondary.main, fontSize: '30px' }}>
                            {role === undefined
                                ? '같은 현상이 지속된다면 담당자에게 문의해주세요.'
                                : '관리자에게 문의해주세요.'}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Unauthorized;
