const SubscribableOffset = [
    {
        value: '',
        label: '구독여부',
        form: 'subscribable'
    },
    {
        value: 'True',
        label: '구독',
        form: 'subscribable'
    },
    {
        value: 'False',
        label: '비구독',
        form: 'subscribable'
    }
];

export default SubscribableOffset;
