/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    CS Menu Routes
*/
import { lazy } from 'react';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData, isAdministrator, isOperator } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';
import menuItem from 'fluv/menuItems';

const UserList = Loadable(lazy(() => import('fluv/pages/CS/User/UserList/common')));
const ChannelList = Loadable(lazy(() => import('fluv/pages/CS/User/Channels/Management/common')));
const UserDetail = Loadable(lazy(() => import('fluv/pages/CS/User/UserDetail')));
const UserReportType = Loadable(lazy(() => import('fluv/pages/CS/User/ReportTrigger/common')));
const ChannelDetail = Loadable(lazy(() => import('fluv/pages/CS/User/Channels/ChannelDetail/common')));
const ChannelReportType = Loadable(lazy(() => import('fluv/pages/CS/Channel/ReportTrigger/common')));
const Article = Loadable(lazy(() => import('fluv/pages/CS/Article/Article')));
const ArticleDetail = Loadable(lazy(() => import('fluv/pages/CS/Article/ArticleDetail')));
const HotArticle = Loadable(lazy(() => import('fluv/pages/CS/Article/HotArticle')));
const ArticleReportType = Loadable(lazy(() => import('fluv/pages/CS/Article/ReportTrigger/common')));
const CommentReportType = Loadable(lazy(() => import('fluv/pages/CS/Comment/ReportTrigger/common')));
const Inquiry = Loadable(lazy(() => import('fluv/pages/CS/Inquiry/Inquiry')));
const InquiryType = Loadable(lazy(() => import('fluv/pages/CS/Inquiry/InquiryType')));
const UserBan = Loadable(lazy(() => import('fluv/pages/CS/User/Ban')));
const UserBanType = Loadable(lazy(() => import('fluv/pages/CS/User/BanType')));

const Faq = Loadable(lazy(() => import('fluv/pages/CS/Faq/Faq')));
const Store = Loadable(lazy(() => import('fluv/pages/CS/Payment/Store/Store')));
const StoreDetail = Loadable(lazy(() => import('fluv/pages/CS/Payment/Store/StoreDetail')));
const Exchange = Loadable(lazy(() => import('fluv/pages/CS/Payment/Exchange/Exchange')));
const ExchangeDetail = Loadable(lazy(() => import('fluv/pages/CS/Payment/Exchange/ExchangeDetail')));
const Order = Loadable(lazy(() => import('fluv/pages/CS/Payment/Order/Order')));
const OrderDetail = Loadable(lazy(() => import('fluv/pages/CS/Payment/Order/OrderDetail')));
const Advertisement = Loadable(lazy(() => import('fluv/pages/CS/Advertisement/Advertisement')));
const Reward = Loadable(lazy(() => import('fluv/pages/CS/Advertisement/Reward')));
const Comment = Loadable(lazy(() => import('fluv/pages/CS/Comment/Comment')));
const CommentDetail = Loadable(lazy(() => import('fluv/pages/CS/Comment/CommentDetail')));
const CashoutHistory = Loadable(lazy(() => import('fluv/pages/CS/Cashout/History/common')));
const ConversionHistory = Loadable(lazy(() => import('fluv/pages/CS/Conversion/History/common')));
const CategoryType = Loadable(lazy(() => import('fluv/pages/CS/User/Category/CategoryType/common')));
const AssignCategory = Loadable(lazy(() => import('fluv/pages/CS/User/Category/AssignCategory/common')));
const UnAuthrorized = Loadable(lazy(() => import('exodus/components/unauthorized/Unauthorized')));
const ReportManger = Loadable(lazy(() => import('fluv/pages/CS/ReportManager')));
const PurchaseArticles = Loadable(lazy(() => import('fluv/pages/CS/PurchaseArticles')));

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}
const CSRoutes = state => {
    if (state && state !== role) {
        window.location.reload();
    }
    return {
        path: '/cs/*',
        element: (
            <AuthGuard>
                <MainLayout menuItem={menuItem} />
            </AuthGuard>
        ),
        children: [
            {
                path: 'user/all',
                exact: true,
                element: <UserList />
            },
            {
                path: 'user/:userId/:tab',
                exact: true,
                element: <UserDetail />
            },
            {
                path: 'user/channels',
                exact: true,
                element: <ChannelList />
            },
            {
                path: 'user/ban',
                exact: true,
                element: <UserBan />
            },
            {
                path: 'user/ban/type',
                exact: true,
                element: <UserBanType />
            },
            {
                path: 'user/report/type',
                exact: true,
                element: <UserReportType />
            },
            {
                path: 'user/channels/:channelId/:tab',
                exact: true,
                element: <ChannelDetail />
            },
            {
                path: 'channel/report/type',
                exact: true,
                element: <ChannelReportType />
            },
            {
                path: 'article',
                exact: true,
                element: <Article />
            },
            {
                path: 'article/:articleId/:tab',
                exact: true,
                element: <ArticleDetail />
            },
            {
                path: 'article/hot',
                exact: true,
                element: <HotArticle />
            },
            {
                path: 'article/report/type',
                exact: true,
                element: <ArticleReportType />
            },
            {
                path: 'comment',
                exact: true,
                element: <Comment />
            },
            {
                path: 'comment/:commentId/:tab',
                exact: true,
                element: <CommentDetail />
            },
            {
                path: 'article/purchase',
                exact: true,
                element: <PurchaseArticles />
            },
            {
                path: 'inquiry',
                exact: true,
                element: <Inquiry />
            },
            {
                path: 'inquiry/:inquiryId',
                exact: true,
                element: <Inquiry />
            },
            {
                path: 'inquiry/type',
                exact: true,
                element: <InquiryType />
            },
            {
                path: 'reportManager/:tab',
                exact: true,
                element: <ReportManger />
            },
            {
                path: 'comment/report/type',
                exact: true,
                element: <CommentReportType />
            },
            {
                path: 'faq',
                exact: true,
                element: <Faq />
            },
            {
                path: 'payment/store',
                exact: true,
                element: isAdministrator(role) ? <Store /> : <UnAuthrorized role={role} />
            },
            {
                path: 'payment/store/:storeId',
                exact: true,
                element: <StoreDetail />
            },
            {
                path: 'payment/exchange',
                exact: true,
                element: <Exchange />
            },
            {
                path: 'payment/exchange/:exchangeId',
                exact: true,
                element: <ExchangeDetail />
            },
            {
                path: 'payment/order',
                exact: true,
                element: <Order />
            },
            {
                path: 'payment/order/:orderId',
                exact: true,
                element: <OrderDetail />
            },
            {
                path: 'advertisement',
                exact: true,
                element: <Advertisement />
            },
            {
                path: 'advertisement/reward',
                exact: true,
                element: <Reward />
            },
            {
                path: 'cashout/history',
                exact: true,
                element: isAdministrator(role) ? <CashoutHistory /> : <UnAuthrorized role={role} />
            },
            {
                path: 'conversion/history',
                exact: true,
                element: <ConversionHistory />
            },
            {
                path: 'user/category/type',
                exact: true,
                element: <CategoryType />
            },
            {
                path: 'user/category/assign',
                exact: true,
                element: isOperator(role) ? <AssignCategory /> : <UnAuthrorized role={role} />
            },
            { path: '*', element: <NotFound /> }
        ]
    };
};

export default CSRoutes;
