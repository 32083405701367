const ArticleTypeOffset = [
    {
        value: '',
        label: '종류',
        form: 'type'
    },
    {
        value: 'I',
        label: '이미지',
        form: 'type'
    },
    {
        value: 'V',
        label: '비디오',
        form: 'type'
    }
];

export default ArticleTypeOffset;
