import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as promotionAPI from 'fluv/lib/api/promotionAPI';
import LocaleOffset from 'fluv/utils/Offset/LocaleOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_PROMOTION';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_PROMOTION';
const [GET_PROMOTION, GET_PROMOTION_SUCCESS, GET_PROMOTION_FAILURE] = createRequestActionTypes('fluv/GET_PROMOTION');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getPromotion = createAction(GET_PROMOTION, ({ locale }) => ({
    locale
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    promotionList: null,
    filter: {
        locale: {
            value: '',
            label: '언어',
            offset: LocaleOffset
        }
    }
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getPromotionSaga = createRequestSaga(GET_PROMOTION, promotionAPI.getPromotion);
export function* promotionSaga() {
    yield takeLatest(GET_PROMOTION, getPromotionSaga);
}

// ==============================|| REDUCER ||============================== //

const promotion = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_PROMOTION_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            promotionList: data.data
        }),
        [GET_PROMOTION_FAILURE]: state => ({
            ...state,
            promotionList: initialState.promotionList
        })
    },
    initialState
);

export default promotion;
