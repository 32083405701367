/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    Channel REDUX
*/
import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as channelAPI from 'fluv/lib/api/channelAPI';
import ChannelTypeOffset from 'fluv/utils/Offset/ChannelTypeOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_CHANNEL';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_CHANNEL';
const [GET_CHANNEL, GET_CHANNEL_SUCCESS, GET_CHANNEL_FAILURE] = createRequestActionTypes('fluv/GET_CHANNEL');
const [GET_CHANNEL_MORE, GET_CHANNEL_MORE_SUCCESS, GET_CHANNEL_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_CHANNEL_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getChannel = createAction(
    GET_CHANNEL,
    ({ cursor, limit, category, nickname, userId, channelId, type }) => ({
        cursor,
        limit,
        category,
        nickname,
        userId,
        channelId,
        type
    })
);

export const getChannelMore = createAction(
    GET_CHANNEL_MORE,
    ({ cursor, limit, category, nickname, userId, channelId, type }) => ({
        cursor,
        limit,
        category,
        nickname,
        userId,
        channelId,
        type
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    channelList: null,
    cursor: null,
    limit: 20,
    getMore: true,
    search: {
        input: '',
        target: 'nickname'
    },
    filter: {
        channelType: {
            value: '',
            label: '채널 종류',
            form: 'channelType',
            offset: ChannelTypeOffset
        }
    }
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getChannelSaga = createRequestSaga(GET_CHANNEL, channelAPI.getChannels);
const getChannelMoreSaga = createRequestSaga(GET_CHANNEL_MORE, channelAPI.getChannels);

export function* channelSaga() {
    yield takeLatest(GET_CHANNEL, getChannelSaga);
    yield takeLatest(GET_CHANNEL_MORE, getChannelMoreSaga);
}

// ==============================|| REDUCER ||============================== //

const channel = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_CHANNEL_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            channelList: data.data.objects,
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_CHANNEL_FAILURE]: state => ({
            ...state,
            channelList: 'error'
        }),
        [GET_CHANNEL_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            channelList: state.channelList.concat(data.data.objects),
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_CHANNEL_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default channel;
