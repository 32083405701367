import { lazy } from 'react';

import Loadable from 'exodus/components/ui/load/Loadable';
import Unauthorized from 'exodus/components/unauthorized/Unauthorized';
import menuItem from 'exodus/menuItems';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData, isAdministrator } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

const AdminAccountPage = Loadable(lazy(() => import('exodus/pages/Admin/Account/Account')));
const AdminAccountEditPage = Loadable(lazy(() => import('exodus/pages/Admin/Account/AccountEdit')));

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}
const AdminRoutes = state => {
    if (state && state !== role) {
        window.location.reload();
    }
    return {
        path: '/admin/*',
        exact: true,
        element: (
            <AuthGuard>
                <MainLayout menuItem={menuItem} />
            </AuthGuard>
        ),
        children: [
            {
                path: 'account',
                exact: true,
                element: isAdministrator(role) ? <AdminAccountPage /> : <Unauthorized role={role} />
            },
            {
                path: 'account/:userId',
                exact: true,
                element: isAdministrator(role) ? <AdminAccountEditPage /> : <Unauthorized role={role} />
            },
            { path: '*', element: <NotFound /> }
        ]
    };
};

export default AdminRoutes;
