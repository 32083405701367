import { openFluvSnackBar } from 'store/modules/exodus/snackbar';

const fluvMiddleware = store => next => action => {
    try {
        switch (action.type) {
            // NOTICE
            case 'fluv/DELETE_NOTICE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_NOTICE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/DELETE_EVENT_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_EVENT_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // INQUIRY
            case 'fluv/PUT_INQUIRY_ANSWER_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '답변 완료' }));
                break;
            case 'fluv/PUT_INQUIRY_ANSWER_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/POST_TRANSLATE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: '번역 실패' }));
                break;
            case 'fluv/POST_BANNER_ACTION_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '생성 완료' }));
                break;
            case 'fluv/POST_INQUIRY_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '생성 완료' }));
                break;
            case 'fluv/POST_INQUIRY_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/PUT_INQUIRY_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '수정 완료' }));
                break;
            case 'fluv/PUT_INQUIRY_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/DELETE_INQUIRY_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_INQUIRY_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // BANNER
            case 'fluv/POST_BANNER_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '생성 완료' }));
                break;
            case 'fluv/POST_BANNER_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/PUT_BANNER_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '수정 완료' }));
                break;
            case 'fluv/PUT_BANNER_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/DELETE_BANNER_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_BANNER_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // Push
            case 'fluv/POST_PUSH_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: 'PUSH 발행 완료' }));
                break;
            case 'fluv/POST_PUSH_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/CANCEL_RESERVE_PUSH_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: 'PUSH 예약 취소 완료' }));
                break;
            case 'fluv/CANCEL_RESERVE_PUSH_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // ReportType
            case 'fluv/POST_REPORT_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '생성 완료' }));
                break;
            case 'fluv/POST_REPORT_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/PUT_REPORT_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '수정 완료' }));
                break;
            case 'fluv/PUT_REPORT_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/DELETE_REPORT_TYPE_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_REPORT_TYPE_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // Report
            case 'fluv/PUT_REPORT_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '확인 완료' }));
                break;
            case 'fluv/PUT_REPORT_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            // Faq
            case 'fluv/POST_FAQ_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '생성 완료' }));
                break;
            case 'fluv/POST_FAQ_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/PUT_FAQ_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '수정 완료' }));
                break;
            case 'fluv/PUT_FAQ_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            case 'fluv/DELETE_FAQ_SUCCESS':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'success', message: '삭제 완료' }));
                break;
            case 'fluv/DELETE_FAQ_FAILURE':
                store.dispatch(openFluvSnackBar({ alertSeverity: 'error', message: action.payload.data.msg }));
                break;
            default:
                break;
        }
    } catch (e) {
        // server disconnect error
        store.dispatch(openFluvSnackBar({ type: 'error', message: 'server error' }));
    }

    const result = next(action);

    return result;
};

export default fluvMiddleware;
