/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>

    MenuItems - CS
*/

import {
    IconAd,
    IconAd2,
    IconAlarm,
    IconArchive,
    IconBadge,
    IconCash,
    IconCreditCard,
    IconDeviceTv,
    IconDiamond,
    IconFlag,
    IconFlame,
    IconHeartHandshake,
    IconInbox,
    IconJewishStar,
    IconListCheck,
    IconListDetails,
    IconMessageCircle2,
    IconMessageReport,
    IconPencilPlus,
    IconPhoneCall,
    IconPictureInPicture,
    IconPolaroid,
    IconQuestionMark,
    IconReceipt2,
    IconReplace,
    IconShoppingCart,
    IconSlideshow,
    IconSquareX,
    IconTags,
    IconTimeline,
    IconUser,
    IconUserCheck,
    IconUserCircle,
    IconUserExclamation,
    IconUserMinus,
    IconUserOff,
    IconUserPlus,
    IconWallet
} from '@tabler/icons';

const icons = {
    IconAlarm,
    IconBadge,
    IconPhoneCall,
    IconPictureInPicture,
    IconFlag,
    IconInbox,
    IconDeviceTv,
    IconMessageReport,
    IconUserCheck,
    IconQuestionMark,
    IconPolaroid,
    IconSquareX,
    IconCreditCard,
    IconShoppingCart,
    IconReplace,
    IconReceipt2,
    IconAd,
    IconAd2,
    IconDiamond,
    IconMessageCircle2,
    IconListDetails,
    IconListCheck,
    IconUser,
    IconUserOff,
    IconSlideshow,
    IconFlame,
    IconHeartHandshake,
    IconUserPlus,
    IconCash,
    IconWallet,
    IconJewishStar,
    IconUserExclamation,
    IconUserMinus,
    IconUserCircle,
    IconTags,
    IconPencilPlus,
    IconTimeline,
    IconArchive
};

const cs = {
    id: 'cs',
    title: 'C/S',
    type: 'group',
    children: [
        {
            id: 'user',
            title: '유저',
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'user',
                    title: '유저 관리',
                    type: 'item',
                    url: '/flirting/cs/user/all',
                    icon: icons.IconUserCheck,
                    breadcrumbs: true
                },
                {
                    id: 'ban',
                    title: '차단 유저 관리',
                    type: 'item',
                    url: '/flirting/cs/user/ban',
                    icon: icons.IconUserOff
                },
                {
                    id: 'conversion',
                    title: '챌린저 전환',
                    type: 'item',
                    icon: icons.IconJewishStar,
                    url: '/flirting/cs/conversion/history',
                    breadcrumbs: true
                },
                {
                    id: 'channel',
                    title: '채널 관리',
                    type: 'item',
                    url: '/flirting/cs/user/channels',
                    icon: icons.IconDeviceTv,
                    breadcrumbs: true
                },
                {
                    id: 'category',
                    title: '카테고리',
                    type: 'collapse',
                    icon: icons.IconTags,
                    children: [
                        {
                            id: 'categoryType',
                            title: '카테고리 생성',
                            type: 'item',
                            url: '/flirting/cs/user/category/type',
                            icon: icons.IconPencilPlus,
                            breadcrumbs: true
                        },
                        {
                            id: 'categoryAssign',
                            title: '카테고리 부여',
                            type: 'item',
                            url: '/flirting/cs/user/category/assign',
                            icon: icons.IconTimeline,
                            breadcrumbs: true
                        }
                    ]
                }
            ]
        },

        {
            id: 'article',
            title: '콘텐츠',
            type: 'collapse',
            icon: icons.IconSlideshow,
            children: [
                {
                    id: 'article',
                    title: '게시글 관리',
                    type: 'item',
                    url: '/flirting/cs/article',
                    icon: icons.IconPolaroid,
                    breadcrumbs: true
                },
                {
                    id: 'hot',
                    title: '인기 게시글 관리',
                    type: 'item',
                    url: '/flirting/cs/article/hot',
                    icon: icons.IconFlame,
                    breadcrumbs: true
                },
                {
                    id: 'comment',
                    title: '댓글 관리',
                    type: 'item',
                    url: '/flirting/cs/comment',
                    icon: icons.IconMessageCircle2,
                    breadcrumbs: true
                },
                {
                    id: 'purchaseArticles',
                    title: '구매 게시글 관리',
                    type: 'item',
                    url: '/flirting/cs/article/purchase',
                    icon: icons.IconArchive,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'inquiry',
            title: '문의/신고',
            type: 'collapse',
            icon: icons.IconPhoneCall,
            children: [
                {
                    id: 'inquiry',
                    title: '문의/답변 관리',
                    type: 'item',
                    url: '/flirting/cs/inquiry',
                    icon: icons.IconPhoneCall,
                    breadcrumbs: true
                },
                {
                    id: 'reportManager',
                    title: '신고 관리',
                    type: 'item',
                    url: '/flirting/cs/reportManager/users',
                    icon: icons.IconFlag,
                    breadcrumbs: true
                },
                {
                    id: 'inquiryType',
                    title: 'FAQ 관리',
                    type: 'item',
                    url: '/flirting/cs/faq',
                    icon: icons.IconQuestionMark,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'payment',
            title: '결제/환전',
            type: 'collapse',
            icon: icons.IconCreditCard,
            children: [
                {
                    id: 'storeManager',
                    title: '결제',
                    type: 'collapse',
                    icon: icons.IconReplace,
                    children: [
                        {
                            id: 'store',
                            title: '상품 관리',
                            type: 'item',
                            icon: icons.IconShoppingCart,
                            breadcrumbs: true,
                            url: '/flirting/cs/payment/store'
                        },
                        {
                            id: 'order',
                            title: '결제 내역',
                            type: 'item',
                            icon: icons.IconReceipt2,
                            breadcrumbs: true,
                            url: '/flirting/cs/payment/order'
                        }
                    ]
                },
                {
                    id: 'cashout',
                    title: '환전',
                    type: 'collapse',
                    icon: icons.IconCash,
                    children: [
                        {
                            id: 'cashout',
                            title: '환전 내역',
                            type: 'item',
                            icon: icons.IconWallet,
                            breadcrumbs: true,
                            url: '/flirting/cs/cashout/history'
                        }
                    ]
                }
            ]
        }
        // {
        //     id: 'advertisement',
        //     title: '광고',
        //     type: 'collapse',
        //     icon: icons.IconAd,
        //     children: [
        //         {
        //             id: 'advertisement',
        //             title: '광고 내역',
        //             type: 'item',
        //             icon: icons.IconAd2,
        //             breadcrumbs: true,
        //             url: '/flirting/cs/advertisement'
        //         },
        //         {
        //             id: 'reward',
        //             title: '보상 내역',
        //             type: 'item',
        //             icon: icons.IconDiamond,
        //             breadcrumbs: true,
        //             url: '/flirting/cs/advertisement/reward'
        //         }
        //     ]
        // },

        // }
    ]
};

export default cs;
