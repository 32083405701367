// third party
import { lazy } from 'react';
import { useSelector } from 'react-redux';

import { Route, Routes } from 'react-router-dom';

// routing
import AUTH from 'exodus/routes/AuthRoutes';

// defaultTheme
import FILLIT from 'fillit/routes';

import themes from './exodus/themes';

import CELEB from 'celeb/routes';

// project imports
import Snackbar from 'exodus/components/ui/extended/Snackbar';
import Loadable from 'exodus/components/ui/load/Loadable';
import NavigationScroll from 'exodus/components/ui/NavigationScroll';
import EXODUS from 'exodus/routes';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';
import FLUV from 'fluv/routes';
import IDOL from 'idol/routes';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

const Default = Loadable(lazy(() => import('exodus/pages/Default/Default')));
const NotFound = Loadable(lazy(() => import('exodus/pages/NotFound')));

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector(state => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <AuthGuard>
                                    <Default />
                                </AuthGuard>
                            }
                        />
                        <Route path="/auth/*" element={<AUTH />} />
                        <Route path="/exodus/*" element={<EXODUS />} />
                        <Route path="/idol/*" element={<IDOL />} />
                        <Route path="/celeb/*" element={<CELEB />} />
                        <Route path="/flirting/*" element={<FLUV />} />
                        <Route path="/fillit/*" element={<FILLIT />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Snackbar />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
