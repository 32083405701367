import { IconDotsVertical } from '@tabler/icons';
import PropTypes from 'prop-types';

// material-ui
import ServiceContainer from 'exodus/components/main/container/ServiceContainer';
import Transitions from 'exodus/components/ui/extended/Transitions';

import { AppBar, Box, ButtonBase, ClickAwayListener, Grid, Paper, Popper, Toolbar } from '@mui/material';

// project imports
// import LocalizationContainer from 'common/components/main/container/LocalizationContainer';
// assets

// ==============================|| MOBILE HEADER ||============================== //

const Mobile = ({ theme, matchMobile, open, anchorRef, handleToggle, handleClose }) => (
    <>
        <Box component="span" ref={anchorRef} sx={{ mt: 1, ml: 1 }}>
            <ButtonBase
                centerRipple
                sx={{ color: theme.palette.mode === 'dark' ? 'primary.main' : 'inherit', ml: 0.5, cursor: 'pointer' }}
            >
                <IconDotsVertical
                    stroke={1.5}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ fontSize: '1.5rem' }}
                />
            </ButtonBase>
        </Box>
        <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ width: '100%', zIndex: 1 }}
            popperOptions={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, matchMobile ? 30 : 10]
                        }
                    }
                ]
            }}
        >
            {({ TransitionProps }) => (
                <Transitions type="zoom" in={open} {...TransitionProps} sx={{ transformOrigin: 'top right' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <AppBar
                                color="inherit"
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
                                    }
                                }}
                            >
                                <Toolbar sx={{ pt: 2.75, pb: 2.75 }}>
                                    <Grid
                                        container
                                        justifyContent={matchMobile ? 'flex-start' : 'flex-start'}
                                        alignItems="center"
                                    >
                                        <ServiceContainer />
                                        {/* <LocalizationContainer /> */}
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                        </ClickAwayListener>
                    </Paper>
                </Transitions>
            )}
        </Popper>
    </>
);

Mobile.propTypes = {
    theme: PropTypes.object,
    matchMobile: PropTypes.bool,
    open: PropTypes.bool,
    anchorRef: PropTypes.object,
    handleToggle: PropTypes.func,
    handleClose: PropTypes.func
};

export default Mobile;
