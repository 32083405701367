// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import * as ReactDOM from 'react-dom/client';

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from 'redux-saga';

// project imports
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import rootReducer, { rootSaga } from 'store/modules';

import config from 'exodus/config';

import authMiddleware from 'exodus/lib/middleware/authMiddleware';
import fluvMiddleware from 'fluv/lib/middleware/fluvMiddleware';
// style + assets
import 'exodus/assets/scss/style.scss';
import './i18n';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, authMiddleware, fluvMiddleware))
);
const persister = persistStore(store);
sagaMiddleware.run(rootSaga);

// ==============================|| REACT DOM RENDER  ||============================== //
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
