import { IconMenu2 } from '@tabler/icons';
import PropTypes from 'prop-types';

// material-ui

// project imports
import LogoSection from 'exodus/components/main/component/sidebar/LogoSection';
// import SearchContainer from 'exodus/components/main/container/SearchContainer';
// import LocalizationContainer from 'common/components/main/container/LocalizationContainer';
import MobileContainer from 'exodus/components/main/container/MobileContainer';
import ProfileContainer from 'exodus/components/main/container/ProfileContainer';
import ServiceContainer from 'exodus/components/main/container/ServiceContainer';

import { Avatar, Box, ButtonBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import NotificationContainer from 'common/components/main/container/NotificationContainer';

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background:
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.secondary.main
                                    : theme.palette.secondary.dark,
                            '&:hover': {
                                background:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.main
                                        : theme.palette.secondary.dark,
                                color:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.light
                                        : theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchContainer /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* live customization & localization */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <ServiceContainer />
            </Box>

            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}><LocalizationContainer /></Box> */}

            {/* notification & profile */}
            {/* <NotificationContainer /> */}

            <ProfileContainer />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileContainer />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
