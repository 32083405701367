import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as articleAPI from 'fluv/lib/api/articleAPI';
import ArticleTypeOffset from 'fluv/utils/Offset/ArticleTypeOffset';
import BlockOffset from 'fluv/utils/Offset/BlockOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_ARTICLE';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_ARTICLE';
const [GET_ARTICLE, GET_ARTICLE_SUCCESS, GET_ARTICLE_FAILURE] = createRequestActionTypes('fluv/GET_ARTICLE');
const [GET_ARTICLE_MORE, GET_ARTICLE_MORE_SUCCESS, GET_ARTICLE_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_ARTICLE_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getArticle = createAction(
    GET_ARTICLE,
    ({ cursor, limit, isViewable, isBlocked, type, nickname, channelId, articleId }) => ({
        cursor,
        limit,
        isViewable,
        isBlocked,
        type,
        channelId,
        nickname,
        articleId
    })
);

export const getArticleMore = createAction(
    GET_ARTICLE_MORE,
    ({ cursor, limit, isViewable, isBlocked, type, nickname, channelId, articleId }) => ({
        cursor,
        limit,
        isViewable,
        isBlocked,
        type,
        channelId,
        nickname,
        articleId
    })
);

// ==============================|| STATE ||============================== //

export const initialState = {
    articleList: null,
    filter: {
        block: {
            value: '',
            label: '차단 여부',
            form: 'block',
            offset: BlockOffset
        },
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        type: {
            value: '',
            label: '종류',
            form: 'type',
            offset: ArticleTypeOffset
        }
    },
    cursor: null,
    limit: 30,
    getMore: true,
    search: {
        input: '',
        target: 'nickname'
    }
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getArticleSaga = createRequestSaga(GET_ARTICLE, articleAPI.getArticle);
const getArticleMoreSaga = createRequestSaga(GET_ARTICLE_MORE, articleAPI.getArticle);

export function* articleSaga() {
    yield takeLatest(GET_ARTICLE, getArticleSaga);
    yield takeLatest(GET_ARTICLE_MORE, getArticleMoreSaga);
}

// ==============================|| REDUCER ||============================== //

const article = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_ARTICLE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            articleList: data.data.objects,
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_ARTICLE_FAILURE]: state => ({
            ...state,
            articleList: 'error'
        }),
        [GET_ARTICLE_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            articleList: state.articleList.concat(data.data.objects),
            cursor: data.data.cursor,
            getMore: data.data.cursor !== null
        }),
        [GET_ARTICLE_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default article;
