const PushTargetFilterOffest = [
    {
        value: '',
        label: '푸시 종류',
        form: 'pushTarget'
    },
    {
        value: 'general',
        label: '일반',
        form: 'pushTarget'
    },
    {
        value: 'notice',
        label: '공지사항',
        form: 'pushTarget'
    },
    {
        value: 'event',
        label: '이벤트',
        form: 'pushTarget'
    },
    {
        value: 'push_new_onepick',
        label: '최애 새 게시물',
        form: 'pushTarget'
    },
    {
        value: 'alarm_new_comment',
        label: '댓글',
        form: 'pushTarget'
    },
    {
        value: 'push_new_reply',
        label: '답글',
        form: 'pushTarget'
    },
    {
        value: 'push_new_follower',
        label: '팔로우',
        form: 'pushTarget'
    },
    {
        value: 'push_new_onepicker',
        label: '최애 챌린저',
        form: 'pushTarget'
    }
];

export default PushTargetFilterOffest;
