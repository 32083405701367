/*
    Copyright (C) 2023 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    role utils
*/

import CryptoJS from 'crypto-js';

const magicString = process.env.REACT_APP_AUTH_MAGIC_STRING;

// 어드민 권한
const Roles = { viewer: 'viewer', operator: 'operator', administrator: 'administrator' };

// viewer인 경우 허용되는 메소드
const viewerMethods = ['get'];

// 타임스탬프와 권한을 암호화
export function encryptTimestampWithAdmin(timestamp, role) {
    const dataToEncrypt = `${magicString}${timestamp}${role}`;
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, 'encryptionSecretKey').toString();
    return encryptedData;
}

// 암호화된 값을 복호화하는 함수
export function decryptData(encryptedData = '') {
    let r;
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'encryptionSecretKey');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    const timestampRegex = /[0-9]+/;
    const permissionRegex = new RegExp(`${timestampRegex.source}(.*)$`);
    const match = decryptedData.match(permissionRegex);
    if (match && match.length > 1) {
        r = match[1];
    }
    return r;
}

// 현재 타임스탬프를 가져오는 함수
export function getCurrentTimestamp() {
    return Math.floor(Date.now() / 1000).toString();
}

export function isAdministrator(role) {
    let r;
    switch (role) {
        case 'administrator':
            r = true;
            break;
        default:
            r = false;
    }
    return r;
}

export function isOperator(role) {
    let r;
    switch (role) {
        case 'administrator':
        case 'operator':
            r = true;
            break;
        default:
            r = false;
    }
    return r;
}

export function authorizeApiRequest(role, config) {
    // Viewer 권한이면서 GET요청인 경우 TRUE
    if (role === Roles.viewer && viewerMethods.includes(config.method)) return true;
    // Operator, Administrator 권한인 경우 TRUE
    if (role === Roles.operator || role === Roles.administrator) return true;
    // 그 외의 경우 FALSE
    return false;
}

export function worksAuthorizeApiRequest(role, config) {
    // Viewer 권한이면서 GET요청인 경우 TRUE
    if (role === Roles.viewer && viewerMethods.includes(config.method)) return true;
    // Operator, Administrator 권한인 경우 TRUE
    if (role === Roles.operator || role === Roles.administrator) return true;
    // 그 외의 경우 FALSE
    return false;
}
