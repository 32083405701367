import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getCommentList = ({
    offset,
    limit,
    articleId,
    commentId,
    userId,
    nickname,
    isComment,
    isBlocked,
    isViewable
}) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/`, {
        params: {
            offset,
            limit,
            article_id: articleId,
            comment_id: commentId,
            user_id: userId,
            nickname,
            is_comment: isComment,
            is_blocked: isBlocked,
            is_viewable: isViewable
        }
    });

export const getCommentDetail = ({ commentId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/detail/${commentId}/`, {});

export const getCommentReport = ({ offset, limit, commentId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/log/${commentId}/`, {
        params: { offset, limit }
    });

export const getComment = ({ offset, limit, articleId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/${articleId}/`, {
        params: { offset, limit }
    });

export const getReply = ({ offset, limit, commentId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/reply/${commentId}/`, {
        params: { offset, limit }
    });

export const getReport = ({ nickname, cursor, limit, isChecked, reportTypeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/`, {
        params: { cursor, limit, is_checked: isChecked, report_type_id: reportTypeId, nickname }
    });
export const putReport = ({ reportId, isChecked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/${reportId}/`, {
        is_checked: isChecked
    });
export const getReportType = ({ cursor, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/type/`, {
        params: { cursor, limit, is_viewable: isViewable }
    });
export const postReportType = ({ priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/type/`, {
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putReportType = ({ reportTypeId, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/type/${reportTypeId}/`, {
        reportTypeId,
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteReportType = ({ reportTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/comments/report/type/${reportTypeId}/`, {});
