import PropTypes from 'prop-types';

// material-ui

// third-party

// project imports
import { drawerWidth } from 'store/constant';

import SubDrawer from 'exodus/components/main/component/sidebar/SubDrawer';

import { Box, Drawer } from '@mui/material';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, theme, matchUpMd, container, menuItem }) => (
    <Box
        component="nav"
        sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
        aria-label="mailbox folders"
    >
        <Drawer
            container={container}
            variant={matchUpMd ? 'persistent' : 'temporary'}
            anchor="left"
            open={drawerOpen}
            onClose={drawerToggle}
            sx={{
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderRight: 'none',
                    [theme.breakpoints.up('md')]: {
                        top: '88px'
                    }
                }
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
        >
            <SubDrawer matchUpMd={matchUpMd} menuItem={menuItem} />
        </Drawer>
    </Box>
);

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    theme: PropTypes.object,
    matchUpMd: PropTypes.bool,
    container: PropTypes.element,
    menuItem: PropTypes.object
};

export default Sidebar;
