import { lazy } from 'react';

import menuItem from 'fillit/menuItems';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

const DashboardDefaultPage = Loadable(lazy(() => import('fillit/pages/Dashboard/Default')));

const DashboardRoutes = {
    path: '/dashboard/*',
    element: (
        <AuthGuard>
            <MainLayout menuItem={menuItem} />
        </AuthGuard>
    ),
    children: [
        {
            path: 'default',
            exact: true,
            element: <DashboardDefaultPage />
        },

        { path: '*', element: <NotFound /> }
    ]
};

export default DashboardRoutes;
