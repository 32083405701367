import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getConfig = ({ offset, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/configs/`, {
        params: { offset, limit }
    });

export const getConfigDetail = ({ configId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/configs/${configId}/`, {});

export const putConfig = ({ configId, valueAlt, beginAt, endAt }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/configs/${configId}/`, {
        _value_alt: valueAlt,
        begin_at: beginAt,
        end_at: endAt
    });
