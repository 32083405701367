import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as eventAPI from 'fluv/lib/api/eventAPI';
import LocaleOffset from 'fluv/utils/Offset/LocaleOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_EVENT';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_EVENT';
const [GET_EVENT, GET_EVENT_SUCCESS, GET_EVENT_FAILURE] = createRequestActionTypes('fluv/GET_EVENT');
const [GET_EVENT_MORE, GET_EVENT_MORE_SUCCESS, GET_EVENT_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_EVENT_MORE');
const [DELETE_EVENT, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE] = createRequestActionTypes('fluv/DELETE_EVENT');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getEvent = createAction(GET_EVENT, ({ offset, limit, isViewable, search, locale }) => ({
    offset,
    limit,
    isViewable,
    search,
    locale
}));

export const getEventMore = createAction(GET_EVENT_MORE, ({ offset, limit, isViewable, search, locale }) => ({
    offset,
    limit,
    isViewable,
    search,
    locale
}));

export const deleteEvent = createAction(DELETE_EVENT, ({ eventId }) => ({
    eventId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    eventList: null,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        locale: {
            value: '',
            label: '언어',
            offset: LocaleOffset
        }
    },
    offset: 0,
    limit: 20,
    getMore: true,
    search: {
        input: ''
    }
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getEventSaga = createRequestSaga(GET_EVENT, eventAPI.getEvents);
const getEventMoreSaga = createRequestSaga(GET_EVENT_MORE, eventAPI.getEvents);
const deleteEventSaga = createRequestSaga(DELETE_EVENT, eventAPI.deleteEvent);

export function* eventSaga() {
    yield takeLatest(GET_EVENT, getEventSaga);
    yield takeLatest(GET_EVENT_MORE, getEventMoreSaga);
    yield takeLatest(DELETE_EVENT, deleteEventSaga);
}

// ==============================|| REDUCER ||============================== //

const event = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_EVENT_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            eventList: data.data,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_EVENT_FAILURE]: state => ({
            ...state,
            eventList: 'error'
        }),
        [GET_EVENT_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            eventList: state.eventList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_EVENT_MORE_FAILURE]: state => ({
            ...state
        }),
        [DELETE_EVENT_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            eventList: state.eventList.filter(item => item.id !== data.data)
        }),
        [DELETE_EVENT_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default event;
