// third party
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

// project imports
import AdminRoutes from 'exodus/routes/AdminRoutes';
import ProfileRtoues from 'exodus/routes/ProfileRoutes';

import NotFound from 'exodus/pages/NotFound';
// import AuthGuard from 'common/utils/route-guard/AuthGuard';

// const PagesLanding = Loadable(lazy(() => import('common/components/landing')));

export default function CommonRoutes() {
    const role = useSelector(state => state.auth.role);
    return useRoutes([AdminRoutes(role), ProfileRtoues, { path: '*', element: <NotFound /> }]);

    // 아래는 인증 로직 구현되면 사용할 route
    // return useRoutes([
    //     {
    //         path: '/',
    //         exact: true,
    //         element: (
    //             <AuthGuard>
    //                 <Main />
    //             </AuthGuard>
    //         )
    //     },
    //     AuthRoutes
    // ]);

    // 브라우저 정책으로 인해 각 어드민 API 서버는 CORS 및 CSRF 설정이 필요
    // CORS를 모두 ALLOW 할 예정이 아니면, 개발 환경을 위해 각 어드민 API 서버마다 CORS 화이트리스트에 localhost를 추가 필요
    // 서비스별 ADMIN API 서버가 다를 경우 CSRF 토큰도 다르기 때문에 CSRF 기능은 사용하지 않는 것이 공수가 덜 들것으로 보임
}
// 1. 로그인 시 AuthRoute로 Default 경로인 "/" 경로 이동
// 2. 비 로그인 상태일 시 모든 routes는 /login 으로 이동
// 3. 로그인 시 /auth/login, /register 접근 불가
// 4. Default 경로에서 idol, celeb, fluv 으로 이동하는 Contents 제공
// 5. Default 페이지와 서비스 관리자 페이지의 Appbar 는 서로  다름
// 5-1. 현재는 개발을 위해 Default 페이지는 로그인/비로그인 상태 모두 접근가능하며 로그인 유무에 따라 Profile 뷰가 다름
// 5-2. 서비스 관리자 페이지 이동시 Appbar 에서 별도 추가 레이아웃 구성 예정
