const PushStatusOffest = [
    {
        value: '',
        label: '푸시 상태',
        form: 'pushStatus'
    },
    {
        value: 'push',
        label: '성공',
        form: 'pushStatus'
    },
    {
        value: 'error',
        label: '실패',
        form: 'pushStatus'
    },
    {
        value: 'alarm',
        label: '알림',
        form: 'pushStatus'
    },
    {
        value: 'onepick',
        label: '최애 챌린저',
        form: 'pushStatus'
    },
    {
        value: 'filtered',
        label: '필터링',
        form: 'pushStatus'
    }
];

export default PushStatusOffest;
