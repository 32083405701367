/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    Payment API
*/
import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getStore = ({ offset, limit, isViewable, isSubscribable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/store/`, {
        params: { offset, limit, is_viewable: isViewable, is_subscribable: isSubscribable }
    });

export const getStoreDetail = ({ storeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/store/${storeId}/`, {});

export const getExchange = ({ offset, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/exchange/`, {
        params: { offset, limit, is_viewable: isViewable }
    });

export const getExchangeDetail = ({ exchangeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/exchange/${exchangeId}/`, {});

export const getOrder = ({ cursor, limit, userId, nickname }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/order/`, {
        params: { cursor, limit, user_id: userId, nickname }
    });

export const getOrderDetail = ({ orderId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/payments/order/${orderId}/`, {});
