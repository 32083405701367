import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as bottomSheetAPI from 'fluv/lib/api/bannerAPI';
import LocaleOffset from 'fluv/utils/Offset/LocaleOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_BOTTOM_SHEET';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_BOTTOM_SHEET';
const [GET_BOTTOM_SHEET, GET_BOTTOM_SHEET_SUCCESS, GET_BOTTOM_SHEET_FAILURE] =
    createRequestActionTypes('fluv/GET_BOTTOM_SHEET');
const [GET_BOTTOM_SHEET_MORE, GET_BOTTOM_SHEET_MORE_SUCCESS, GET_BOTTOM_SHEET_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_BOTTOM_SHEET_MORE');
const [DELETE_BOTTOM_SHEET, DELETE_BOTTOM_SHEET_SUCCESS, DELETE_BOTTOM_SHEET_FAILURE] =
    createRequestActionTypes('fluv/DELETE_BOTTOM_SHEET');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getBottomSheet = createAction(
    GET_BOTTOM_SHEET,
    ({ offset, limit, isViewable, search, locale, bannerType, type }) => ({
        offset,
        limit,
        isViewable,
        search,
        locale,
        bannerType,
        type
    })
);

export const getBottomSheetMore = createAction(
    GET_BOTTOM_SHEET_MORE,
    ({ offset, limit, isViewable, search, locale, bannerType, type }) => ({
        offset,
        limit,
        isViewable,
        search,
        locale,
        bannerType,
        type
    })
);

export const deletegetBottomSheet = createAction(DELETE_BOTTOM_SHEET, ({ bannerId }) => ({
    bannerId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    bottomSheetList: null,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        locale: {
            value: '',
            label: '언어',
            offset: LocaleOffset
        }
    },
    offset: 0,
    limit: 20,
    getMore: true,
    search: {
        input: ''
    }
};

// ==============================|| SAGA ||============================== //

// bottomSheet saga
const getBottomSheetSaga = createRequestSaga(GET_BOTTOM_SHEET, bottomSheetAPI.getBanner);
const getBottomSheetMoreSaga = createRequestSaga(GET_BOTTOM_SHEET_MORE, bottomSheetAPI.getBanner);
const deleteBottomSheetSaga = createRequestSaga(DELETE_BOTTOM_SHEET, bottomSheetAPI.deleteBanner);

export function* bottomSheetSaga() {
    // bottomSheet
    yield takeLatest(GET_BOTTOM_SHEET, getBottomSheetSaga);
    yield takeLatest(GET_BOTTOM_SHEET_MORE, getBottomSheetMoreSaga);
    yield takeLatest(DELETE_BOTTOM_SHEET, deleteBottomSheetSaga);
}

const bottomSheet = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_BOTTOM_SHEET_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bottomSheetList: data.data,
            preview: initialState.preview,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_BOTTOM_SHEET_FAILURE]: state => ({
            ...state,
            bottomSheetList: 'error',
            preview: initialState.preview
        }),
        [GET_BOTTOM_SHEET_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bottomSheetList: state.bottomSheetList.concat(data.data),
            preview: initialState.preview,
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_BOTTOM_SHEET_MORE_FAILURE]: state => ({
            ...state
        }),
        [DELETE_BOTTOM_SHEET_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bottomSheetList: state.bottomSheetList.filter(item => item.id !== data.data)
        }),
        [DELETE_BOTTOM_SHEET_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default bottomSheet;
