/* eslint-disable no-alert */
/* eslint no-underscore-dangle: 0 */
/*
    Copyright (C) 2023 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    api settings
*/

// third party
import axios from 'axios';

// project imports
import { apiConfig } from 'exodus/config';
import { authorizeApiRequest, decryptData } from 'exodus/utils/role';

const flirtingAdminRole = localStorage.getItem('flirting-admin-role');
let role;

if (!flirtingAdminRole) {
    role = 'viewer';
} else {
    role = decryptData(flirtingAdminRole);
}

export const req = axios.create({
    // 비 로그인 상태 axios
    timeout: 180000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export const authReq = axios.create({
    // 로그인 상태 axios
    timeout: 180000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export const authWorksReq = axios.create({
    // 로그인 상태 axios
    timeout: 180000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

authWorksReq.interceptors.request.use(
    config => {
        // token check
        const token = localStorage.getItem(apiConfig.worksAccessToken);
        // if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/json';
        // } else {
        //     throw new axios.Cancel('Unauthorized');
        // }
        return config;
    },
    error => Promise.reject(error)
);

authReq.interceptors.request.use(
    config => {
        // token check
        const token = localStorage.getItem(apiConfig.accessToken);
        if (authorizeApiRequest(role, config)) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['Content-Type'] = 'application/json';
            // config.headers.withCredentials = false;
        } else {
            throw new axios.Cancel('Unauthorized');
        }
        return config;
    },
    error => Promise.reject(error)
);

authReq.interceptors.response.use(
    // token refresh
    // http status 401(Unauthorized) 인 경우 토큰 갱신 후, 다시 요청
    // fluv 인증 기준으로 작성되었습니다.
    response => response,
    async err => {
        if (err.message === 'Unauthorized') {
            alert('접근권한이 없습니다.\n관리자에게 문의하세요.');
            throw Error('Unauthorized');
        }
        const originalReq = err.config;
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // 토큰 만료시
            originalReq._retry = true;
            try {
                // 토큰 갱신
                const res = await axios.post(`${apiConfig.authAdminApiUrl}/api/v1/accounts/token/refresh/`, {
                    refresh_token: localStorage.getItem(apiConfig.refreshToken)
                });
                const data = res.data;
                // 토큰 갱신 성공시 로컬스토리지 및 기존 요청 access token 변경
                localStorage.setItem(apiConfig.accessToken, data.data.access_token);
                localStorage.setItem(apiConfig.refreshToken, data.data.refresh_token);
                originalReq.headers.Authorization = `Bearer ${data.data.access_token}`;
                return axios(originalReq);
            } catch (error) {
                // 토큰 갱신 실패시 로그아웃
                window.location.href = '/auth/logout';
                return Promise.reject(err);
            }
        }
        return Promise.reject(err);
    }
);
