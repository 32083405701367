import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

// routes
import ContentsRoutes from 'fillit/routes/ContentsRoutes';
import CSRoutes from 'fillit/routes/CSRoutes';
import DashboardRoutes from 'fillit/routes/DashboardRoutes';
import MarketingRoutes from 'fillit/routes/MarketingRoutes';

import NotFound from 'exodus/pages/NotFound';

export default function FluvRoutes() {
    const role = useSelector(state => state.auth.role);
    return useRoutes([
        DashboardRoutes,
        CSRoutes(role),
        MarketingRoutes,
        ContentsRoutes(role),
        /* input your Routes */ { path: '*', element: <NotFound /> }
    ]);
}
