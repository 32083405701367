import { lazy } from 'react';

import { useRoutes } from 'react-router-dom';

import Loadable from 'exodus/components/ui/load/Loadable';
import MinimalLayout from 'exodus/components/ui/MinimalLayout';
import NavMotion from 'exodus/components/ui/NavMotion';
import Logout from 'exodus/pages/Auth/Logout';
import NotFound from 'exodus/pages/NotFound';
import GuestGuard from 'exodus/utils/route-guard/GuestGuard';

const Login = Loadable(lazy(() => import('exodus/pages/Auth/Login')));
const Register = Loadable(lazy(() => import('exodus/pages/Auth/Register')));

export default function AuthRoutes() {
    return useRoutes([
        {
            path: '',
            element: (
                <NavMotion>
                    <GuestGuard>
                        <MinimalLayout />
                    </GuestGuard>
                </NavMotion>
            ),
            children: [
                {
                    path: 'login',
                    exact: true,
                    element: <Login />
                },
                {
                    path: 'register',
                    exact: true,
                    element: <Register />
                }
            ]
        },
        {
            path: 'logout',
            exact: true,
            element: <Logout />
        },
        { path: '*', element: <NotFound /> }
    ]);
}
