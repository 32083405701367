import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getArticle = ({ cursor, limit, type, isViewable, isBlocked, nickname, channelId, articleId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/`, {
        params: {
            cursor,
            limit,
            type,
            nickname,
            is_viewable: isViewable,
            is_blocked: isBlocked,
            channel_id: channelId,
            article_id: articleId
        }
    });

export const getArticleDetail = ({ articleId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/${articleId}/`, {});

export const getArticleReport = ({ offset, limit, articleId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/log/${articleId}/`, {
        params: { offset, limit }
    });

export const getHotArticles = ({ cursor, limit }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/hot/`, {
        params: { cursor, limit }
    });

// Article Report API
export const getReport = ({ cursor, limit, isChecked }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/`, {
        params: { cursor, limit, is_checked: isChecked }
    });

export const putReport = ({ reportId, isChecked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/${reportId}/`, {
        is_checked: isChecked
    });

// Article Report Type API
export const getReportType = ({ cursor, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/type/`, {
        params: { cursor, limit, is_viewable: isViewable }
    });

export const postReportType = ({ priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/type/`, {
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putReportType = ({ reportTypeId, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/type/${reportTypeId}/`, {
        reportTypeId,
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteReportType = ({ reportTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/report/type/${reportTypeId}/`, {});

// Article Block API
export const putBlock = ({ targetId, isBlocked }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/articles/block/${targetId}/`, {
        is_blocked: isBlocked
    });
