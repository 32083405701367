import config from 'exodus/config';

// ==============================|| ACTIONS ||============================== //

export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// ==============================|| STATE ||============================== //

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    navType: config.theme,
    presetColor: config.presetColor,
    locale: config.i18n,
    rtlLayout: config.rtlLayout,
    opened: true
};

// ==============================|| REDUCER ||============================== //

const customization = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };

        case MENU_TYPE:
            return {
                ...state,
                navType: action.navType
            };
        case PRESET_COLORS:
            return {
                ...state,
                presetColor: action.presetColor
            };
        case THEME_LOCALE:
            return {
                ...state,
                locale: action.locale
            };
        case THEME_RTL:
            return {
                ...state,
                rtlLayout: action.rtlLayout
            };
        case SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case SET_OUTLINED_FILLED:
            return {
                ...state,
                outlinedFilled: action.outlinedFilled
            };
        default:
            return state;
    }
};

export default customization;
