import { lazy } from 'react';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';
import menuItem from 'fluv/menuItems';

const DashboardDefaultPage = Loadable(lazy(() => import('fluv/pages/Dashboard/Default')));
const Contest = Loadable(lazy(() => import('fluv/pages/Dashboard/Contest/Contest')));
const History = Loadable(lazy(() => import('fluv/pages/Dashboard/Contest/History')));

const DashboardRoutes = {
    path: '/dashboard/*',
    element: (
        <AuthGuard>
            <MainLayout menuItem={menuItem} />
        </AuthGuard>
    ),
    children: [
        {
            path: 'default',
            exact: true,
            element: <DashboardDefaultPage />
        },
        {
            path: 'contest',
            exact: true,
            element: <Contest />
        },
        {
            path: 'contest/history',
            exact: true,
            element: <History />
        },
        { path: '*', element: <NotFound /> }
    ]
};

export default DashboardRoutes;
