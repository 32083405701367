import { IconLego, IconLock, IconUserCheck, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconUsers,
    IconLock,
    IconLego
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const profile = {
    id: 'profile',
    title: '프로필',
    type: 'group',
    children: [
        {
            id: 'self',
            title: '프로필 정보',
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'self',
                    title: '내 프로필',
                    type: 'item',
                    url: '/exodus/profile/self',
                    icon: icons.IconLego,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default profile;
