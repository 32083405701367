// material-ui
import { Card, CardContent, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

const MenuCard = () => (
    <CardStyle>
        <CardContent sx={{ p: 2 }}>
            <Container>
                <Typography variant="subtitle2" component="div" color="inherit">
                    &#169; Exodus Ent. Co., Ltd.
                </Typography>
            </Container>
        </CardContent>
    </CardStyle>
);

export default MenuCard;
