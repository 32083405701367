/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    MenuItme Component - Marketing
*/

import {
    IconAnchor,
    IconBell,
    IconBellPlus,
    IconBellRinging,
    IconBellRinging2,
    IconBusinessplan,
    IconCalendarEvent,
    IconFilePlus,
    IconGift,
    IconHistory,
    IconLayoutBottombar,
    IconNotes,
    IconPictureInPicture,
    IconReplace,
    IconSettings,
    IconSpeakerphone,
    IconTicket
} from '@tabler/icons';

const icons = {
    IconSpeakerphone,
    IconCalendarEvent,
    IconPictureInPicture,
    IconBell,
    IconBellRinging,
    IconBellRinging2,
    IconBellPlus,
    IconSettings,
    IconLayoutBottombar,
    IconReplace,
    IconAnchor,
    IconGift,
    IconHistory,
    IconBusinessplan,
    IconTicket,
    IconNotes,
    IconFilePlus
};

const marketing = {
    id: 'marketing',
    title: 'Marketing',
    type: 'group',
    children: [
        {
            id: 'notice-event',
            title: '공지/이벤트',
            type: 'collapse',
            icon: icons.IconAnchor,
            children: [
                {
                    id: 'notice',
                    title: '공지사항',
                    type: 'item',
                    url: '/flirting/marketing/notice',
                    icon: icons.IconSpeakerphone,
                    breadcrumbs: true
                },
                {
                    id: 'event',
                    title: '이벤트',
                    type: 'item',
                    url: '/flirting/marketing/event',
                    icon: icons.IconCalendarEvent,
                    breadcrumbs: true
                }
            ]
        },
        // {
        //     id: 'banners',
        //     title: '배너',
        //     type: 'item',
        //     url: '/flirting/marketing/banner',
        //     icon: icons.IconPictureInPicture,
        //     breadcrumbs: true
        // },
        {
            id: 'bottom-sheet',
            title: '바텀 시트',
            type: 'item',
            url: '/flirting/marketing/bottom-sheet',
            icon: icons.IconLayoutBottombar,
            breadcrumbs: true
        },
        // {
        //     id: 'promotion',
        //     title: '프로모션',
        //     type: 'item',
        //     url: '/flirting/marketing/promotion',
        //     icon: icons.IconReplace,
        //     breadcrumbs: true
        // },
        {
            id: 'push',
            title: 'PUSH',
            type: 'collapse',
            icon: icons.IconBell,
            children: [
                {
                    id: 'push',
                    title: '(일반/예약) PUSH',
                    type: 'item',
                    url: '/flirting/marketing/push',
                    icon: icons.IconBellRinging,
                    breadcrumbs: true
                },
                // deprecated
                // {
                //     id: 'reserve',
                //     title: '예약 PUSH',
                //     type: 'item',
                //     url: '/flirting/marketing/push/reserve',
                //     icon: icons.IconBellRinging2,
                //     breadcrumbs: true
                // },
                {
                    id: 'channel',
                    title: '채널 PUSH 발행',
                    type: 'item',
                    url: '/flirting/marketing/push/publish/channel',
                    icon: icons.IconBellPlus,
                    breadcrumbs: true
                },
                {
                    id: 'users',
                    title: '유저 PUSH 발행',
                    type: 'item',
                    url: '/flirting/marketing/push/publish/users',
                    icon: icons.IconBellPlus,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'reward',
            title: '리워드',
            type: 'collapse',
            icon: icons.IconGift,
            children: [
                {
                    id: 'reward',
                    title: '리워드 지급',
                    type: 'item',
                    url: '/flirting/marketing/reward',
                    icon: icons.IconGift,
                    breadcrumbs: true
                },
                {
                    id: 'reward-history',
                    title: '리워드 지급 내역',
                    type: 'item',
                    url: '/flirting/marketing/reward/history',
                    icon: icons.IconHistory,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'coupon',
            title: '쿠폰',
            type: 'collapse',
            icon: icons.IconTicket,
            children: [
                {
                    id: 'coupon',
                    title: '쿠폰 생성',
                    type: 'item',
                    url: '/flirting/marketing/coupons/add',
                    icon: icons.IconFilePlus,
                    breadcrumbs: true
                },
                {
                    id: 'coupon-usage-history',
                    title: '쿠폰 생성 관리',
                    type: 'item',
                    url: '/flirting/marketing/coupons',
                    icon: icons.IconNotes,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'donations',
            title: '후원',
            type: 'item',
            url: '/flirting/marketing/donations',
            icon: icons.IconBusinessplan,
            breadcrumbs: true
        },
        {
            id: 'config',
            title: '설정',
            type: 'item',
            url: '/flirting/marketing/config',
            icon: icons.IconSettings,
            breadcrumbs: true
        }
    ]
};

export default marketing;
