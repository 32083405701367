import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import * as accountAPI from 'exodus/lib/api/accountAPI';
import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import UserTypeOffset from 'exodus/utils/Offset/UserTypeOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'exodus/CHANGE_FIELD_ACCOUNT';
const CHANGE_FILTER = 'exodus/CHANGE_FILTER_ACCOUNT';
const [GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE] = createRequestActionTypes('exodus/GET_ACCOUNT');
const [GET_ACCOUNT_MORE, GET_ACCOUNT_MORE_SUCCESS, GET_ACCOUNT_MORE_FAILURE] =
    createRequestActionTypes('exodus/GET_ACCOUNT_MORE');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getAccount = createAction(GET_ACCOUNT, ({ cursor, limit, email, nickname, role }) => ({
    cursor,
    limit,
    email,
    nickname,
    role
}));

export const getAccountMore = createAction(GET_ACCOUNT_MORE, ({ cursor, limit, email, nickname, role }) => ({
    cursor,
    limit,
    email,
    nickname,
    role
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    accountList: null,
    filter: {
        type: {
            value: '',
            label: '사용자 권한',
            form: 'type',
            offset: UserTypeOffset
        }
    },
    cursor: null,
    limit: 20,
    getMore: true,
    search: {
        input: '',
        target: 'nickname'
    }
};

// ==============================|| SAGA ||============================== //

// account saga
const getAccountSaga = createRequestSaga(GET_ACCOUNT, accountAPI.getAccount);
const getAccountMoreSaga = createRequestSaga(GET_ACCOUNT_MORE, accountAPI.getAccount);

export function* accountSaga() {
    // account
    yield takeLatest(GET_ACCOUNT, getAccountSaga);
    yield takeLatest(GET_ACCOUNT_MORE, getAccountMoreSaga);
}

const account = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_ACCOUNT_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            accountList: data.data.objects,
            preview: initialState.preview,
            cursor: data.data.cursor,
            getMore: Object.keys(data.data.objects).length === state.limit
        }),
        [GET_ACCOUNT_FAILURE]: state => ({
            ...state,
            accountList: 'error',
            preview: initialState.preview
        }),
        [GET_ACCOUNT_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            accountList: state.accountList.concat(data.data.objects),
            preview: initialState.preview,
            cursor: data.data.cursor,
            getMore: Object.keys(data.data.objects).length === state.limit
        }),
        [GET_ACCOUNT_MORE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default account;
