const UserTypeOffset = [
    {
        value: '',
        label: '사용자 권한',
        form: 'type'
    },
    {
        value: 'viewer',
        label: '뷰어',
        form: 'type'
    },
    {
        value: 'operator',
        label: '운영자',
        form: 'type'
    },
    {
        value: 'administrator',
        label: '관리자',
        form: 'type'
    }
];

export default UserTypeOffset;
