const BlockOffset = [
    {
        value: '',
        label: '차단 여부',
        form: 'block'
    },
    {
        value: 'True',
        label: '차단',
        form: 'block'
    },
    {
        value: 'False',
        label: '미차단',
        form: 'block'
    }
];

export default BlockOffset;
