import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';
import { formatDateToUTCStringByDataType } from 'exodus/utils/date/formatDateToString';

// banner

export const getBanner = ({ offset, limit, isViewable, search, locale, bannerTarget, type }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/`, {
        params: { offset, limit, is_viewable: isViewable, search, locale, banner_target: bannerTarget, type }
    });

export const getBannerDetail = ({ bannerId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/${bannerId}/`, {});

export const postBanner = ({
    title,
    priority,
    bannerTarget,
    payload,
    imageUrl,
    isViewable,
    locale,
    type,
    extraTarget,
    publishedAt,
    expiredAt
}) => {
    const publishedUTC = formatDateToUTCStringByDataType(publishedAt);
    const expiredUTC = expiredAt ? formatDateToUTCStringByDataType(expiredAt) : null;
    const requestData = {
        title,
        priority,
        banner_target: bannerTarget,
        payload,
        image_url: imageUrl,
        is_viewable: isViewable,
        locale,
        type,
        extra_target: extraTarget,
        published_at: publishedUTC
    };

    if (expiredAt !== null) {
        requestData.expired_at = expiredUTC;
    }

    return authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/`, requestData);
};

export const putBanner = ({
    bannerId,
    title,
    priority,
    bannerTarget,
    payload,
    imageUrl,
    isViewable,
    locale,
    type,
    extraTarget,
    publishedAt,
    expiredAt
}) => {
    const publishedUTC = formatDateToUTCStringByDataType(publishedAt);
    const expiredUTC = expiredAt && formatDateToUTCStringByDataType(expiredAt);

    const requestData = {
        title,
        priority,
        banner_target: bannerTarget,
        payload,
        image_url: imageUrl,
        is_viewable: isViewable,
        locale,
        type,
        extra_target: extraTarget,
        published_at: publishedUTC
    };
    if (expiredAt !== null) {
        requestData.expired_at = expiredUTC;
    }
    return authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/${bannerId}/`, requestData);
};

export const deleteBanner = ({ bannerId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/${bannerId}/`, {});

export const postPresignedUrl = ({ ext }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/banners/presigned_url/`, {
        ext
    });
