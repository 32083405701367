const SuperuserOffset = [
    {
        value: '',
        label: '유저 권한',
        form: 'superuser'
    },
    {
        value: 'True',
        label: '슈퍼 유저',
        form: 'superuser'
    },
    {
        value: 'False',
        label: '일반 유저',
        form: 'superuser'
    }
];

export default SuperuserOffset;
