/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    
    Locale Offset for Filter
*/
const LocaleOffset = [
    {
        value: '',
        label: '언어',
        form: 'locale'
    },
    {
        value: 'ko',
        label: '한국어',
        form: 'locale'
    },
    {
        value: 'en',
        label: '영어',
        form: 'locale'
    },
    {
        value: 'ja',
        label: '일본어',
        form: 'locale'
    },
    {
        value: 'zh-cn',
        label: '간체',
        form: 'locale'
    },
    {
        value: 'zh-tw',
        label: '번체',
        form: 'locale'
    }
];

export default LocaleOffset;
