const RoleOffset = [
    {
        value: '',
        label: '역할',
        form: 'role'
    },
    {
        value: 'supporter',
        label: '서포터',
        form: 'role'
    },
    {
        value: 'challenger',
        label: '챌린저',
        form: 'role'
    },
    {
        value: 'operator',
        label: '운영자',
        form: 'role'
    },
    {
        value: 'administrator',
        label: '관리자',
        form: 'role'
    },
    {
        value: 'viewer',
        label: '뷰어',
        form: 'role'
    }
];

export default RoleOffset;
