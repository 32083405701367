import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getEvents = ({ offset, limit, isViewable, search, locale }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/`, {
        params: { offset, limit, is_viewable: isViewable, search, type: 'event', locale }
    });

export const getEventDetail = ({ eventId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${eventId}/`);

export const postEvent = ({ title, content, priority, isViewable, locale, publishedAt }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/`, {
        title,
        content,
        priority,
        is_viewable: isViewable,
        type: 'event',
        locale,
        published_at: publishedAt
    });

export const putEvent = ({ eventId, title, content, priority, isViewable, locale, publishedAt }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${eventId}/`, {
        eventId,
        title,
        content,
        priority,
        is_viewable: isViewable,
        type: 'event',
        locale,
        published_at: publishedAt
    });

export const deleteEvent = ({ eventId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/notices/${eventId}/`);
