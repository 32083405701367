import { req } from './settings';

import { apiConfig } from 'exodus/config';

export const userLogin = ({ email, password }) =>
    req.post(`${apiConfig.authAdminApiUrl}/api/admin/accounts/login/`, { provider: 'local', email, password });

export const userRegister = ({ nickname, email, password1, password2 }) =>
    req.post(`${apiConfig.authAdminApiUrl}/api/admin/accounts/register/`, {
        nickname,
        email,
        password1,
        password2
    });
