import { apiConfig } from 'exodus/config';
import { authReq } from 'exodus/lib/api/settings';

export const getReportType = ({ target, offset, limit, isViewable }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/type/`, {
        params: { offset, limit, is_viewable: isViewable }
    });

export const getReportTypeDetail = ({ target, reportTypeId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/type/${reportTypeId}/`, {});

export const postReportType = ({ target, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.post(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/type/`, {
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const putReportType = ({ target, reportTypeId, priority, isViewable, isReason, ko, en, ja, tw, cn }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/type/${reportTypeId}/`, {
        reportTypeId,
        priority,
        is_viewable: isViewable,
        is_reason: isReason,
        name_ko: ko,
        name_en: en,
        name_ja: ja,
        name_zh_tw: tw,
        name_zh_cn: cn
    });

export const deleteReportType = ({ target, reportTypeId }) =>
    authReq.delete(`${apiConfig.fluvAdminApiUrl}/api/admin/${target}/report/type/${reportTypeId}/`, {});
