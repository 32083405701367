import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as bannerAPI from 'fluv/lib/api/bannerAPI';
import LocaleOffset from 'fluv/utils/Offset/LocaleOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_BANNER';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_BANNER';
const [GET_BANNER, GET_BANNER_SUCCESS, GET_BANNER_FAILURE] = createRequestActionTypes('fluv/GET_BANNER');
const [GET_BANNER_MORE, GET_BANNER_MORE_SUCCESS, GET_BANNER_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_BANNER_MORE');
const [DELETE_BANNER, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAILURE] = createRequestActionTypes('fluv/DELETE_BANNER');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getBanner = createAction(
    GET_BANNER,
    ({ offset, limit, isViewable, search, locale, bannerTarget, type }) => ({
        offset,
        limit,
        isViewable,
        search,
        locale,
        bannerTarget,
        type
    })
);

export const getBannerMore = createAction(
    GET_BANNER_MORE,
    ({ offset, limit, isViewable, search, locale, bannerTarget, type }) => ({
        offset,
        limit,
        isViewable,
        search,
        locale,
        bannerTarget,
        type
    })
);

export const deleteBanner = createAction(DELETE_BANNER, ({ bannerId }) => ({
    bannerId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    bannerList: null,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        locale: {
            value: '',
            label: '언어',
            offset: LocaleOffset
        }
    },
    offset: 0,
    limit: 20,
    getMore: true,
    search: {
        input: ''
    }
};

// ==============================|| SAGA ||============================== //

// banner saga
const getBannerSaga = createRequestSaga(GET_BANNER, bannerAPI.getBanner);
const getBannerMoreSaga = createRequestSaga(GET_BANNER_MORE, bannerAPI.getBanner);
const deleteBannerSaga = createRequestSaga(DELETE_BANNER, bannerAPI.deleteBanner);

export function* bannerSaga() {
    // banner
    yield takeLatest(GET_BANNER, getBannerSaga);
    yield takeLatest(GET_BANNER_MORE, getBannerMoreSaga);
    yield takeLatest(DELETE_BANNER, deleteBannerSaga);
}

const banner = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_BANNER_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bannerList: data.data,
            preview: initialState.preview,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_BANNER_FAILURE]: state => ({
            ...state,
            bannerList: 'error',
            preview: initialState.preview
        }),
        [GET_BANNER_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bannerList: state.bannerList.concat(data.data),
            preview: initialState.preview,
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_BANNER_MORE_FAILURE]: state => ({
            ...state
        }),
        [DELETE_BANNER_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            bannerList: state.bannerList.filter(item => item.id !== data.data)
        }),
        [DELETE_BANNER_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default banner;
