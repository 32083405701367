import PropTypes from 'prop-types';

// material-ui
import NavGroup from 'exodus/components/main/component/nav/NavGroup';

import { Typography } from '@mui/material';

// project imports
// 수정
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ menuItem }) => {
    const navItems = menuItem.items.map(item => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

MenuList.propTypes = {
    menuItem: PropTypes.object
};

export default MenuList;
