const EmailCheckedOffset = [
    {
        value: '',
        label: '인증 여부',
        form: 'emailChecked'
    },
    {
        value: 'True',
        label: '인증 완료',
        form: 'emailChecked'
    },
    {
        value: 'False',
        label: '인증 미완료',
        form: 'emailChecked'
    }
];

export default EmailCheckedOffset;
