import PropTypes from 'prop-types';

// material-ui
import Sidebar from 'exodus/components/main/component/sidebar/Sidebar';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
// project imports

// ==============================|| SIDEBAR DRAWER ||============================== //

const SidebarContainer = ({ drawerOpen, drawerToggle, window, menuItem }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Sidebar
            drawerOpen={drawerOpen}
            drawerToggle={drawerToggle}
            window={window}
            theme={theme}
            matchUpMd={matchUpMd}
            container={container}
            menuItem={menuItem}
        />
    );
};

SidebarContainer.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object,
    menuItem: PropTypes.object
};

export default SidebarContainer;
