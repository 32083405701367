// thire party
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

// FLUV STORE
import account, { accountSaga } from './account';

// ==============================|| REDUX - MAIN STORE ||============================== //

const exodusReducer = combineReducers({
    account
});

// ==============================|| REDUX-SAGA - MAIN SAGA ||============================== //

export function* exodusSaga() {
    yield all([accountSaga()]);
}

export default exodusReducer;
