import cs from './cs';
import dashboard from './dashboard';
import marketing from './marketing';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
    items: [dashboard, cs, marketing]
};

export default MenuItems;
