import { apiConfig } from 'exodus/config';
import { authReq, authWorksReq } from 'exodus/lib/api/settings';

export const getAccount = ({ cursor, limit, email, nickname, role }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/users/`, {
        params: {
            cursor,
            limit,
            nickname,
            role,
            email
        }
    });

export const getAccountDetail = ({ userId }) =>
    authReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/users/${userId}/`, {});

export const getAccountSelf = () => authWorksReq.get(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/users/self/`, {});

export const changeProfile = ({ nickname }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/self/`, {
        nickname
    });

export const changeActive = ({ userId }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/active/${userId}/`, {});

export const changePassword = ({ currentPassword, newPassword1, newPassword2 }) =>
    authReq.put(`${apiConfig.fluvAdminApiUrl}/api/admin/accounts/password/change/`, {
        current_password: currentPassword,
        new_password1: newPassword1,
        new_password2: newPassword2
    });

export const patchUserRoles = ({ userId, value }) =>
    authReq.patch(`${apiConfig.fluvAdminApiUrl}/api/admin/users/${userId}/role/`, {
        role: value
    });
