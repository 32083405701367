const PushTypeOffset = [
    {
        value: 'True',
        label: '예약 푸시',
        form: 'pushType'
    },
    {
        value: 'False',
        label: '일반 푸시',
        form: 'pushType'
    }
];

export default PushTypeOffset;
