// thire party
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import exchange, { exchangeSaga } from './exchange';
import store, { storeSaga } from './store';

const payment = combineReducers({
    store,
    exchange
});

export function* paymentSaga() {
    yield all([storeSaga(), exchangeSaga()]);
}

export default payment;
