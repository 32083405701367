import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as pushAPI from 'fluv/lib/api/pushAPI';
import PushChannelOffset from 'fluv/utils/Offset/PushChannelOffset';
import PushTargetFilterOffest from 'fluv/utils/Offset/PushTargetFilterOffset';

// ==============================|| ACTIONS ||============================== //

const CHANGE_FIELD = 'fluv/CHANGE_FIELD_PUSH_RESERVE';
const CHANGE_FILTER = 'fluv/CHANGE_FILTER_PUSH_RESERVE';
const [GET_RESERVE_PUSH, GET_RESERVE_PUSH_SUCCESS, GET_RESERVE_PUSH_FAILURE] =
    createRequestActionTypes('fluv/GET_RESERVE_PUSH');
const [GET_RESERVE_PUSH_MORE, GET_RESERVE_PUSH_MORE_SUCCESS, GET_RESERVE_PUSH_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_RESERVE_PUSH_MORE');
const [CANCEL_RESERVE_PUSH, CANCEL_RESERVE_PUSH_SUCCESS, CANCEL_RESERVE_PUSH_FAILURE] =
    createRequestActionTypes('fluv/CANCEL_RESERVE_PUSH');

export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
    form,
    key,
    value
}));

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getReservePush = createAction(
    GET_RESERVE_PUSH,
    ({ offset, limit, isReserve, search, pushChannel, pushTarget, pushStatus }) => ({
        offset,
        limit,
        isReserve,
        search,
        pushChannel,
        pushTarget,
        pushStatus
    })
);

export const getReservePushMore = createAction(
    GET_RESERVE_PUSH_MORE,
    ({ offset, limit, isReserve, search, pushChannel, pushTarget, pushStatus }) => ({
        offset,
        limit,
        isReserve,
        search,
        pushChannel,
        pushTarget,
        pushStatus
    })
);

export const cancelRservePush = createAction(CANCEL_RESERVE_PUSH, ({ pushId }) => ({
    pushId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    reservePushList: null,
    filter: {
        pushChannel: {
            value: '',
            label: '채널',
            form: 'pushChannel',
            offset: PushChannelOffset
        },
        pushTarget: {
            value: '',
            label: '푸시 종류',
            form: 'pushTarget',
            offset: PushTargetFilterOffest
        }
    },
    offset: 0,
    limit: 20,
    getMore: true,
    search: {
        input: '',
        target: 'nickname'
    }
};

// ==============================|| SAGA ||============================== //

const getRservePushSaga = createRequestSaga(GET_RESERVE_PUSH, pushAPI.getPushes);
const getRservePushMoreSaga = createRequestSaga(GET_RESERVE_PUSH_MORE, pushAPI.getPushes);
const cancelRservePushSaga = createRequestSaga(CANCEL_RESERVE_PUSH, pushAPI.cancelReservePush);

export function* reservePushSaga() {
    yield takeLatest(GET_RESERVE_PUSH, getRservePushSaga);
    yield takeLatest(GET_RESERVE_PUSH_MORE, getRservePushMoreSaga);
    yield takeLatest(CANCEL_RESERVE_PUSH, cancelRservePushSaga);
}

// ==============================|| REDUCER ||============================== //

const reservePush = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_RESERVE_PUSH_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reservePushList: data.data,
            pushList: initialState.pushList,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_RESERVE_PUSH_FAILURE]: state => ({
            ...state,
            reservePushList: 'error',
            pushList: initialState.pushList
        }),
        [GET_RESERVE_PUSH_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reservePushList: state.reservePushList.concat(data.data),
            pushList: initialState.pushList,
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_RESERVE_PUSH_MORE_FAILURE]: state => ({
            ...state,
            pushList: initialState.pushList
        }),
        [CANCEL_RESERVE_PUSH_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reservePushList: state.reservePushList.filter(item => item.id !== data.data)
        }),
        [CANCEL_RESERVE_PUSH_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default reservePush;
