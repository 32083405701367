import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';

import createRequestActionTypes from 'exodus/lib/saga/createRequestActionTypes';
import createRequestSaga from 'exodus/lib/saga/createRequestSaga';
import * as reportTypeAPI from 'fluv/lib/api/report/reportTypeAPI';
import ReportOffset from 'fluv/utils/Offset/ReportOffset';
import ViewableOffset from 'fluv/utils/Offset/ViewableOffset';

const CHANGE_FILTER = 'fluv/CHANGE_FILTER_REPORT_TYPE';
const [GET_REPORT_TYPE, GET_REPORT_TYPE_SUCCESS, GET_REPORT_TYPE_FAILURE] =
    createRequestActionTypes('fluv/GET_REPORT_TYPE');
const [GET_REPORT_TYPE_MORE, GET_REPORT_TYPE_MORE_SUCCESS, GET_REPORT_TYPE_MORE_FAILURE] =
    createRequestActionTypes('fluv/GET_REPORT_TYPE_MORE');
const [DELETE_REPORT_TYPE, DELETE_REPORT_TYPE_SUCCESS, DELETE_REPORT_TYPE_FAILURE] =
    createRequestActionTypes('fluv/DELETE_REPORT_TYPE');

export const changeFilter = createAction(CHANGE_FILTER, ({ form, value, label }) => ({
    form,
    value,
    label
}));

export const getReportType = createAction(GET_REPORT_TYPE, ({ target, offset, limit, isViewable }) => ({
    target,
    offset,
    limit,
    isViewable
}));

export const getReportTypeMore = createAction(GET_REPORT_TYPE_MORE, ({ target, offset, limit, isViewable }) => ({
    target,
    offset,
    limit,
    isViewable
}));

export const deleteReportType = createAction(DELETE_REPORT_TYPE, ({ target, reportTypeId }) => ({
    target,
    reportTypeId
}));

// ==============================|| STATE ||============================== //

export const initialState = {
    reportTypeList: null,
    filter: {
        viewable: {
            value: '',
            label: '공개 여부',
            form: 'viewable',
            offset: ViewableOffset
        },
        type: {
            value: 'users',
            label: '유저',
            form: 'type',
            offset: ReportOffset
        }
    },
    offset: 0,
    limit: 20,
    getMore: true
};

// ==============================|| SAGA ||============================== //

// saga 생성
const getReportTypeSaga = createRequestSaga(GET_REPORT_TYPE, reportTypeAPI.getReportType);
const getReportTypeMoreSaga = createRequestSaga(GET_REPORT_TYPE_MORE, reportTypeAPI.getReportType);
const deleteReportTypeSaga = createRequestSaga(DELETE_REPORT_TYPE, reportTypeAPI.deleteReportType);

export function* reportTypeSaga() {
    yield takeLatest(GET_REPORT_TYPE, getReportTypeSaga);
    yield takeLatest(GET_REPORT_TYPE_MORE, getReportTypeMoreSaga);
    yield takeLatest(DELETE_REPORT_TYPE, deleteReportTypeSaga);
}

// ==============================|| REDUCER ||============================== //

const reportType = handleActions(
    {
        [CHANGE_FILTER]: (state, { payload: { form, value, label } }) =>
            produce(state, draft => {
                draft.filter[form] = { value, label, offset: state.filter[form].offset };
            }),
        [GET_REPORT_TYPE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reportTypeList: data.data,
            offset: initialState.offset,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_REPORT_TYPE_FAILURE]: state => ({
            ...state,
            reportTypeList: 'error'
        }),
        [GET_REPORT_TYPE_MORE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reportTypeList: state.reportTypeList.concat(data.data),
            offset: state.offset + state.limit,
            getMore: Object.keys(data.data).length === state.limit
        }),
        [GET_REPORT_TYPE_MORE_FAILURE]: state => ({
            ...state,
            reportType: initialState.reportType
        }),
        [DELETE_REPORT_TYPE_SUCCESS]: (state, { payload: data }) => ({
            ...state,
            reportTypeList: state.reportTypeList.filter(item => item.id !== data.data)
        }),
        [DELETE_REPORT_TYPE_FAILURE]: state => ({
            ...state
        })
    },
    initialState
);

export default reportType;
