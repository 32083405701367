const StatusOffset = [
    {
        value: '',
        label: '상태',
        form: 'status'
    },
    {
        value: 'banned',
        label: '정지',
        form: 'status'
    },
    {
        value: 'normal',
        label: '일반',
        form: 'status'
    },
    {
        value: 'dropout',
        label: '탈퇴',
        form: 'status'
    },
    {
        value: 'inactive',
        label: '비활성',
        form: 'status'
    }
];

export default StatusOffset;
