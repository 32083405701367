import { useRoutes } from 'react-router-dom';

// routes
import CSRoutes from 'celeb/routes/CSRoutes';
import DashboardRoutes from 'celeb/routes/DashboardRoutes';

import NotFound from 'exodus/pages/NotFound';
// import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

export default function CelebRoutes() {
    return useRoutes([DashboardRoutes, CSRoutes, { path: '*', element: <NotFound /> }]);
}
