/*
    Copyright (C) 2023 ExodusEnt Corp. All rights reserved.
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    utils for route guard
*/

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

// project imports
// 수정 예정

// ==============================|| AUTH GUARD ||============================== //
// 로그인 상태일 경우 접근 가능
/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            // if (isLoggedIn) {
            navigate('/auth/login', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
