// thire party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { all } from 'redux-saga/effects';

import exodusReducer, { exodusSaga } from './exodus';
import auth, { authSaga } from './exodus/auth';
import customization from './exodus/customization';

// EXODUS STORE
import loading from './exodus/loading';
import snackbar from './exodus/snackbar';

// import idolReducer, { idolSaga } from './idol';
// import celebReducer, { celebSaga } from './celeb';
import fillitReducer, { fillitSaga } from './fillit';
import fluvReducer, { fluvSaga } from './fluv';

// ==============================|| REDUX - MAIN STORE ||============================== //

const rootReducer = combineReducers({
    // --------------|| BERRY - SAMPLE ||--------------
    customization: persistReducer(
        {
            key: 'customization',
            storage
        },
        customization
    ),
    // --------------|| COMMON ||--------------
    snackbar,
    auth: persistReducer(
        {
            key: 'auth',
            storage,
            whitelist: ['isLoggedIn', 'user']
        },
        auth
    ),
    loading,
    exodusReducer,
    // idolReducer,
    // celebReducer,
    fluvReducer,
    fillitReducer
});

// ==============================|| REDUX-SAGA - MAIN SAGA ||============================== //

export function* rootSaga() {
    // yield all([authSaga(),idolSaga(), celebSaga(), fluvSaga()]);
    yield all([authSaga(), exodusSaga(), fluvSaga(), fillitSaga()]);
}

export default rootReducer;
