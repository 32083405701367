/*
    Copyright (C) 2022 ExodusEnt Corp. All rights reserved.
    Author: wooseko.cho <wooseok.cho@myloveidol.com>
    Author: byungju.lee <callmedevmomo@myloveidol.com>
    
    CS Menu Routes
*/

import menuItem from 'fillit/menuItems';

import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import { decryptData } from 'exodus/utils/role';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';

const auth = localStorage.getItem('flirting-admin-role');
let role;
if (auth) {
    role = decryptData(auth);
}
const CSRoutes = state => {
    if (state && state !== role) {
        window.location.reload();
    }
    return {
        path: '/cs/*',
        element: (
            <AuthGuard>
                <MainLayout menuItem={menuItem} />
            </AuthGuard>
        ),
        children: [{ path: '*', element: <NotFound /> }]
    };
};

export default CSRoutes;
