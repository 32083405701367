import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import en from './locales/en/common.json';
import ja from './locales/ja/common.json';
import ko from './locales/ko/common.json';
import zhCN from './locales/zh-cn/common.json';
import zhTW from './locales/zh-tw/common.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    ko: {
        translation: ko
    },
    en: {
        translation: en
    },
    ja: { translation: ja },
    'zh-cn': { translation: zhCN },
    'zh-tw': { translation: zhTW }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'ko',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
