import { lazy } from 'react';

import Loadable from 'exodus/components/ui/load/Loadable';
import MainLayout from 'exodus/pages/MainLayout';
import NotFound from 'exodus/pages/NotFound';
import AuthGuard from 'exodus/utils/route-guard/AuthGuard';
import menuItem from 'idol/menuItems';

const CSNotice = Loadable(lazy(() => import('idol/pages/CS/Notice')));
const CSInquiry = Loadable(lazy(() => import('idol/pages/CS/Inquiry')));

const DashboardRoutes = {
    path: '/cs/*',
    element: (
        <AuthGuard>
            <MainLayout menuItem={menuItem} />
        </AuthGuard>
    ),
    children: [
        {
            path: 'notice',
            exact: true,
            element: <CSNotice />
        },
        {
            path: 'inquiry',
            exact: true,
            element: <CSInquiry />
        },
        { path: '*', element: <NotFound /> }
    ]
};

export default DashboardRoutes;
