// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const cs = {
    id: 'cs',
    title: 'C/S',
    type: 'group',
    children: [
        {
            id: 'notice',
            title: '공지사항',
            type: 'item',
            url: '/celeb/cs/notice',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'inquiry',
            title: '문의/답변',
            type: 'item',
            url: '/celeb/cs/inquiry',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        }
    ]
};

export default cs;
