import { useTheme } from '@mui/material/styles';

const ChoedolLogo = () => {
    const theme = useTheme();
    return (
        <svg
            width="130"
            height="32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 130 32"
        >
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M67,1.64c-0.43-0.29-0.95-0.45-1.47-0.44c-0.53-0.01-1.05,0.15-1.5,0.44c-0.47,0.33-0.74,0.88-0.7,1.45V26.7
	c-0.04,0.57,0.23,1.12,0.7,1.45c0.45,0.29,0.97,0.45,1.5,0.44c0.52,0,1.03-0.15,1.47-0.44c0.48-0.32,0.75-0.87,0.71-1.45V3.09
	C67.75,2.51,67.48,1.96,67,1.64z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M62.14,20.11c-0.31-0.36-0.79-0.53-1.26-0.45c-1.82,0.17-3.67,0.29-5.51,0.36v-1.77
	c0.04-0.57-0.23-1.12-0.7-1.45c-0.45-0.29-0.97-0.45-1.5-0.44c-0.52,0-1.03,0.15-1.47,0.44c-0.47,0.32-0.74,0.88-0.7,1.45v1.9h-2.87
	h-3.07c-0.48-0.03-0.95,0.19-1.23,0.59c-0.51,0.8-0.51,1.81,0,2.61c0.28,0.4,0.75,0.62,1.23,0.59c1.48,0,3,0,4.45,0l4.3-0.11
	c1.39,0,2.72-0.09,4-0.17s2.4-0.16,3.43-0.26c0.49-0.01,0.94-0.29,1.17-0.72c0.21-0.41,0.29-0.87,0.24-1.32
	C62.62,20.9,62.44,20.46,62.14,20.11z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M45.22,17.48c0.36,0.35,0.84,0.55,1.34,0.54c0.3,0,0.59-0.09,0.84-0.24l1.79-1c0.54-0.32,1.12-0.69,1.73-1.1
	c0.64-0.39,1.25-0.83,1.82-1.33c0.21-0.19,0.39-0.42,0.54-0.66l0,0c0.12,0.17,0.26,0.32,0.41,0.47c0.22,0.21,0.45,0.4,0.7,0.57
	c0.28,0.2,0.63,0.43,1,0.7c1.15,0.74,2.14,1.34,2.93,1.79c0.46,0.32,1.06,0.38,1.58,0.17c0.42-0.19,0.77-0.5,1-0.9
	c0.25-0.42,0.35-0.91,0.29-1.39c-0.07-0.53-0.39-0.99-0.87-1.24c-0.45-0.25-1-0.55-1.5-0.89s-1.23-0.76-2-1.26
	c-0.51-0.3-0.94-0.71-1.25-1.21c-0.18-0.48-0.25-0.99-0.2-1.5V8.69h4.3c0.49,0.03,0.96-0.2,1.24-0.6c0.26-0.38,0.39-0.84,0.38-1.3
	c0.01-0.46-0.12-0.92-0.38-1.3c-0.28-0.4-0.75-0.62-1.24-0.59h-4.3V3.25c0.04-0.58-0.23-1.13-0.7-1.46c-0.91-0.59-2.09-0.59-3,0
	C51.21,2.13,50.95,2.68,51,3.25V4.9h-4.58c-0.49-0.03-0.96,0.19-1.24,0.59c-0.26,0.38-0.39,0.84-0.38,1.3
	c-0.01,0.46,0.12,0.92,0.38,1.3c0.28,0.4,0.75,0.63,1.24,0.6H51v0.38c0.02,0.54-0.1,1.07-0.34,1.55c-0.31,0.51-0.74,0.93-1.25,1.22
	c-0.73,0.49-1.42,0.92-2,1.3s-1.26,0.75-1.92,1.12c-0.5,0.24-0.85,0.73-0.91,1.28c-0.05,0.48,0.05,0.96,0.3,1.38
	C44.97,17.12,45.09,17.31,45.22,17.48z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M94.48,1.61c-0.41-0.27-0.9-0.41-1.39-0.41c-0.49-0.01-0.98,0.14-1.39,0.41C91.23,1.91,90.96,2.44,91,3v7.5h-2
	V3.28c0.03-0.54-0.22-1.06-0.66-1.37c-0.4-0.26-0.86-0.4-1.34-0.4c-0.48-0.01-0.96,0.13-1.36,0.4C85.2,2.22,84.96,2.74,85,3.28
	v22.24c-0.04,0.54,0.21,1.06,0.66,1.37c0.4,0.27,0.86,0.41,1.34,0.41c0.49,0,0.98-0.14,1.39-0.41c0.43-0.32,0.66-0.84,0.61-1.37
	V14.26h2v12.56c-0.04,0.54,0.21,1.06,0.66,1.36c0.41,0.27,0.9,0.42,1.39,0.41c0.49,0,0.98-0.14,1.39-0.41
	c0.45-0.3,0.7-0.82,0.66-1.36V3C95.16,2.46,94.92,1.93,94.48,1.61z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M76.88,3c-1.99-0.14-3.92,0.77-5.06,2.41c-1,1.57-1.58,3.91-1.58,6.94s0.53,5.36,1.58,6.94
	c1.14,1.64,3.07,2.55,5.06,2.41c1.99,0.14,3.92-0.77,5.06-2.41c1-1.58,1.58-3.91,1.58-6.94S83,7,81.94,5.43
	C80.8,3.78,78.88,2.86,76.88,3z M78.88,16.53c-0.52,1.1-1.84,1.58-2.94,1.06c-0.47-0.22-0.84-0.59-1.06-1.06
	c-0.48-1.34-0.69-2.76-0.62-4.18c-0.07-1.42,0.14-2.85,0.62-4.19c0.53-1.1,1.86-1.57,2.96-1.04c0.45,0.22,0.82,0.58,1.04,1.04
	c0.48,1.34,0.69,2.77,0.62,4.19c0.08,1.43-0.13,2.85-0.61,4.2L78.88,16.53z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M123.62,14.05c-0.27-0.38-0.72-0.59-1.19-0.56h-9.54v-1.72h6.33c0.47,0.03,0.92-0.18,1.19-0.56
	c0.25-0.38,0.38-0.83,0.37-1.28c0.01-0.44-0.12-0.87-0.37-1.24c-0.27-0.38-0.72-0.59-1.19-0.56h-13.46c-0.35,0-0.48-0.13-0.48-0.48
	V6c0-0.34,0.13-0.48,0.48-0.48h13.15c0.47,0.03,0.92-0.18,1.19-0.56c0.26-0.37,0.39-0.82,0.37-1.27c0.02-0.44-0.11-0.88-0.37-1.24
	c-0.27-0.38-0.72-0.59-1.19-0.56h-15.22c-0.79-0.08-1.58,0.14-2.21,0.62c-0.5,0.6-0.73,1.38-0.64,2.16V9
	c-0.08,0.78,0.15,1.56,0.64,2.17c0.63,0.48,1.42,0.7,2.21,0.62h4.86v1.72H99c-0.47-0.03-0.92,0.18-1.19,0.56
	c-0.25,0.37-0.38,0.8-0.37,1.24c-0.01,0.45,0.12,0.9,0.37,1.28c0.27,0.38,0.72,0.59,1.19,0.56h23.42c0.47,0.03,0.92-0.18,1.19-0.56
	c0.25-0.38,0.37-0.83,0.36-1.28C123.99,14.86,123.86,14.42,123.62,14.05z"
            />
            <path
                fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#EF4846'}
                d="M119.75,27.05h-14.49c-0.18,0-0.23-0.05-0.23-0.23v-0.56c0-0.18,0-0.23,0.23-0.23h12.51
	c0.79,0.08,1.58-0.14,2.21-0.62c0.49-0.61,0.72-1.39,0.64-2.17V21c0.09-0.78-0.14-1.56-0.64-2.17c-0.63-0.48-1.42-0.7-2.21-0.62
	h-15.59c-0.45-0.03-0.89,0.18-1.14,0.56c-0.24,0.35-0.36,0.76-0.35,1.18c-0.01,0.42,0.11,0.84,0.35,1.19
	c0.26,0.37,0.69,0.58,1.14,0.55h13.93c0.18,0,0.23,0,0.23,0.23v0.52c0,0.18,0,0.23-0.23,0.23H103.6c-0.79-0.08-1.58,0.14-2.21,0.62
	c-0.49,0.61-0.72,1.39-0.64,2.17v2.28c-0.08,0.78,0.15,1.56,0.64,2.17c0.63,0.48,1.42,0.7,2.21,0.62h16.15
	c0.45,0.03,0.88-0.18,1.14-0.55c0.24-0.35,0.36-0.77,0.35-1.19c0-0.42-0.12-0.83-0.35-1.18C120.63,27.24,120.2,27.02,119.75,27.05z"
            />
            <path
                fill="#E7403B"
                d="M41,29.18l-1.11-1.06l0.36-1.5c0.06-0.22-0.01-0.46-0.18-0.61c-0.16-0.16-0.4-0.22-0.62-0.16l-1.79,0.53
	c-2.89,0.8-5.93,0.88-8.86,0.23c-1.28-0.28-2.52-0.69-3.71-1.23l4.65-4.16L31.1,20c0.84-0.73,1.87-1.68,2.89-2.7
	c1.86-1.85,2.88-4.38,2.82-7c0.11-2.6-0.86-5.13-2.68-7l0,0c-1.71-1.82-4.06-2.89-6.55-3c-1.59,0.03-3.15,0.47-4.52,1.28
	C22.34,2.02,21.65,2.51,21,3.05c-1.17,1.06-2.28,2.18-3.32,3.36c-0.06,0.11-0.11,0.23-0.15,0.35c-0.07,0.2-0.12,0.41-0.14,0.63
	c-0.04,0.29-0.04,0.59,0,0.88c0.06,0.39,0.19,0.77,0.4,1.11c0.24,0.39,0.52,0.75,0.83,1.09l0,0l0.12,0.13
	c0.38,0.36,0.97,0.35,1.33-0.03c0.02-0.02,0.04-0.05,0.06-0.07c0.43-0.58,0.56-0.76,1-1.29c0.12-0.14,0.25-0.29,0.42-0.47l0.33-0.33
	C21.92,8.37,21.96,8.33,22,8.3l0.34-0.36l0,0l0,0l0.08-0.09c0.24-0.24,1.09-1,1.36-1.17l0.1-0.08l0.13-0.1
	c0.78-0.59,1.65-1.04,2.59-1.33c0.39-0.12,0.8-0.18,1.21-0.17c1.24-0.01,2.42,0.55,3.19,1.53c0.92,0.89,1.44,2.11,1.47,3.39
	c0.09,1.34-0.37,2.66-1.27,3.65c-0.86,0.91-1.77,1.78-2.72,2.59l-1.41,1.23l-4.87,4.34l-0.47,0.43c-0.14,0.13-0.49,0.38-0.75,0.58
	l-0.42-0.34l0,0l0,0l-0.08-0.06c-0.93-0.75-2-1.6-3.37-2.82l0,0c0,0-2.38-1.89-5.63-5c-1.05-1.1-1.67-2.53-1.75-4.05
	c-0.02-1.45,0.53-2.85,1.53-3.9C12,5.66,13.07,5.1,14.24,5c0.52,0.02,1.04,0.13,1.52,0.35l3.41-3.42C17.71,0.95,16,0.4,14.24,0.33
	c-2.41,0.03-4.7,1.06-6.34,2.83C6.02,5.08,4.98,7.66,5,10.35c0.09,2.8,1.22,5.46,3.16,7.48c0.88,0.94,1.83,1.81,2.84,2.61
	c0.92,0.74,1.76,1.46,2.58,2.16s1.87,1.59,2.93,2.44l0.36,0.29c-1.11,0.5-2.27,0.9-3.46,1.18c-3.02,0.74-6.19,0.7-9.19-0.13
	l-1.8-0.53C2.2,25.79,1.97,25.84,1.8,26c-0.17,0.15-0.24,0.39-0.18,0.61L2,28.12l-1.13,1.06c-0.17,0.16-0.24,0.4-0.18,0.62
	c0.05,0.22,0.22,0.4,0.44,0.46l1.63,0.48l0,0L3,30.8l0,0l0.15,0.05h0.07h0.11h0.06h0.09h0.07c3.61,0.92,7.38,0.95,11,0.08
	c2.27-0.5,4.44-1.35,6.45-2.51c1.99,1.2,4.15,2.09,6.41,2.63c1.73,0.41,3.5,0.62,5.27,0.62c1.9,0.01,3.79-0.22,5.64-0.67h0.07h0.09
	h0.05h0.12h0.07h0.15h0.05l0.18-0.05l0,0l1.63-0.48c0.22-0.07,0.38-0.24,0.44-0.46C41.32,29.73,41.25,29.38,41,29.18z"
            />
        </svg>
    );
};

export default ChoedolLogo;
